import DividerFeatured from './DividerFeatured';
import { SparklesIcon, FireIcon } from '@heroicons/react/20/solid'
import { MdLandscape, MdHistoryEdu } from "react-icons/md";
import { TiGlobe, TiGroup  } from "react-icons/ti";
import { FaImagePortrait } from "react-icons/fa6";
import { PiBowlFoodFill } from "react-icons/pi";
import { BiSolidCat } from "react-icons/bi";
import { GiAbstract086 } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendarMinus } from "react-icons/fa6";
import { TbMacro } from "react-icons/tb";
import { BsSymmetryVertical } from "react-icons/bs";
import { FaTreeCity } from "react-icons/fa6";
import { VscColorMode } from "react-icons/vsc";
import { FaDraftingCompass } from "react-icons/fa";
import { MdNightlight } from "react-icons/md";
import { IoIosBowtie } from "react-icons/io";
import { FaCity } from "react-icons/fa6";
import { PiSunHorizonFill } from "react-icons/pi";
import { MdAgriculture } from "react-icons/md";
import { FaBridgeWater } from "react-icons/fa6";
import { TbTimelineEventFilled } from "react-icons/tb";
import { MdStars } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import { PiShootingStarFill } from "react-icons/pi";
import { BsFillFileImageFill } from "react-icons/bs";
import { FaHandsHoldingCircle } from "react-icons/fa6";
import { PiBirdFill } from "react-icons/pi";
import { FaHouseFloodWater } from "react-icons/fa6";

function CardGalleryFeatured({ imageUrl, title, location, formatted_date, images_count, isNew, isHot, isFeatured, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, tag11, tag12, tag13, tag14, tag15, tag16, tag17, tag18, tag19, tag20, tag21, tag22, tag23, description, id }) {
    console.log(tag1)
  return (
    <div class="max-w-full mb-4 bg-gray-100 rounded-xl dark:bg-gray-900 border-2 border-gray-100 dark:border-gray-900 overflow-hidden transition-all duration-300 hover:border-sky-600 dark:hover:border-sky-500">
      <Link to={`/portfolio/gallery/${id}`}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="relative">
            <div className='pt-3 md:pb-3 px-3'>
              <img className="h-80 w-full object-cover rounded-xl" src={imageUrl} alt="" />
            </div>
            <div className='absolute top-8 left-8 p-1 rounded-full bg-sky-500 shadow-lg'>
              <span className="flex items-center bg-sky-600 text-white text-lg font-medium px-5 py-0.5 rounded-full"><PiShootingStarFill className="h-5 w-5 mr-1"/>FEATURED</span>
            </div>
          </div>
          <div class="p-3">
            <h5 class="mr-2 mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">{title}</h5>
            <div className="mb-2 flex items-center flex-row">
              {/* {!!isFeatured && (<span className="flex items-center bg-blue-500 text-white text-xs font-medium px-4 py-0.5 rounded-full mr-2"><MdStars className="h-4 w-4 mr-1"/>FEATURED</span>)} */}
              {!!isNew && (<span className="flex items-center bg-green-700 text-green-100 text-xs font-medium px-4 py-1 rounded-full mr-2"><SparklesIcon className="h-4 w-4 mr-1"/>NEW</span>)}
              {!!isHot && (<span className="flex items-center bg-red-700 text-red-100 text-xs font-medium px-4 py-1 rounded-full mr-2"><FireIcon className="h-4 w-4 mr-1"/>HOT</span>)}
            </div>
            <p class="flex items-center font-normal text-gray-500 dark:text-gray-400"><FaLocationDot className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>{location}</p>
            <p class="flex items-center font-normal text-gray-500 dark:text-gray-400"><FaCalendarMinus className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>{formatted_date}</p>
            <p class="flex items-center mb-3 font-normal text-gray-500 dark:text-gray-400"><BsFillFileImageFill className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>{images_count} Photos</p>
            <DividerFeatured/>
            <div className="flex flex-wrap items-center mb-2 pt-2">
              {!!tag1 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><TiGlobe className="h-5 w-5 mr-1"/>Travel</span>)}
              {!!tag2 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><MdLandscape className="h-5 w-5 mr-1"/>Landscape</span>)}
              {!!tag3 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><FaImagePortrait className="h-4 w-4 mr-1"/>Portrait</span>)}
              {!!tag4 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><TiGroup className="h-5 w-5 mr-1"/>Culture</span>)}
              {!!tag5 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><PiBowlFoodFill className="h-5 w-5 mr-1"/>Food</span>)}
              {!!tag6 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><BiSolidCat className="h-5 w-5 mr-1"/>Wildlife</span>)}
              {!!tag7 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><GiAbstract086 className="h-5 w-5 mr-1"/>Abstract</span>)}
              {!!tag8 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><MdHistoryEdu className="h-5 w-5 mr-1"/>Historical</span>)}
              {!!tag9 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><TbMacro className="h-5 w-5 mr-1"/>Macro</span>)}
              {!!tag10 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><BsSymmetryVertical className="h-5 w-5 mr-1"/>Symmetry</span>)}
              {!!tag11 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><FaTreeCity className="h-5 w-5 mr-1"/>Street</span>)}
              {!!tag12 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><VscColorMode className="h-5 w-5 mr-1"/>Black and White</span>)}
              {!!tag13 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><FaDraftingCompass className="h-4 w-4 mr-1"/>Architecture</span>)}
              {!!tag14 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><MdNightlight className="h-5 w-5 mr-1"/>Night</span>)}
              {!!tag15 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><IoIosBowtie className="h-5 w-5 mr-1"/>Fashion</span>)}
              {!!tag16 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><FaCity className="h-5 w-5 mr-1"/>Cityscapes</span>)}
              {!!tag17 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><PiSunHorizonFill className="h-5 w-5 mr-1"/>Sunset/Sunrise</span>)}
              {!!tag18 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><MdAgriculture className="h-5 w-5 mr-1"/>Rural Life</span>)}
              {!!tag19 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><FaBridgeWater className="h-5 w-5 mr-1"/>Underwater</span>)}
              {!!tag20 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><TbTimelineEventFilled className="h-5 w-5 mr-1"/>Events</span>)}
              {!!tag21 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><FaHandsHoldingCircle className="h-5 w-5 mr-1"/>Religious</span>)}
              {!!tag22 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><PiBirdFill className="h-5 w-5 mr-1"/>Birds</span>)}
              {!!tag23 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><FaHouseFloodWater className="h-5 w-5 mr-1"/>Sea</span>)}
            </div>
            <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">{description}</p>
            <Link to={`/portfolio/gallery/${id}`} class="inline-flex items-center px-10 py-2 text-sm font-medium text-center text-gray-900 dark:text-gray-100 hover:text-gray-100  bg-gray-200 dark:bg-gray-800 hover:bg-sky-600 dark:hover:bg-sky-500 overflow-hidden transition-all duration-300 rounded-md">
              View Full Gallery
              <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2 hidden md:block"/>
            </Link>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CardGalleryFeatured;
// IMPORTS : INTERNAL
import NavBar from '../blocks/NavBar';
import BreadCrumb from '../blocks/BreadCrumb';
import Footer from '../blocks/Footer';
import DividerDot from '../blocks/DividerDot';
import IdentityList from '../blocks/IdentityList';

// IMPORTS : EXTERNAL
import { Helmet } from 'react-helmet';
import IdentityFeatured from '../blocks/IdentityFeatured';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';

const pages = [
  { name: 'Portfolio', href: '/portfolio', current: false },
  { name: 'Identity', href: '/portfolio/identity', current: true },
]

function Identity() {
  return (
    <div className="Identity">
      <Helmet><title>Winksen // Identity</title></Helmet>
      <NavBar/>
      <div className="bg-gray-50 dark:bg-gray-950 pb-10">
        <div class="container mx-auto px-10 pt-5">

          {/* BreadCrumb */}
          <BreadCrumb pages={pages}/>

          <DividerDot/>
          <br />

          <Link to={`https://www.behance.net/aminetabia1`} target="_blank" rel="noopener noreferrer" class="mb-4 inline-flex items-center px-10 py-2 text-sm font-medium text-center text-gray-100 dark:text-gray-900 bg-gray-800 dark:bg-gray-200 hover:bg-gray-700 dark:hover:bg-gray-300 overflow-hidden transition-all duration-300 rounded-md">
            Check on Behance
            <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2"/>
          </Link>
          
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl">Discover Beautiful <span class="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">Visual Identities</span>!</h1>
          <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400">Dive into a world where every frame tells a story. Explore Our Image Identity, a curated collection of captivating visuals that freeze moments in time. From the play of light to the raw emotions, each image is a testament to the art of photography and the beauty found in the ordinary.</p>
          <br />

          <div className="container">
            <IdentityFeatured/>
            <IdentityList />
          </div>

          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default Identity;

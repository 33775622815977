// IMPORTS : INTERNAL
import ErrorServer from './ErrorServer';
import ErrorGeneral from './ErrorGeneral';
import LoadingSpinner from './LoadingSpinner';
import LoadingSpinnerPagination from './LoadingSpinnerPagination';
import NotFound from './NotFound';
import BlogCard from './BlogCard';

// IMPORTS : EXTERNAL
import { useState, useEffect } from 'react';
import { FaInfoCircle } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function BlogList() {
  const [serverError, setServerError] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(1);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => { fetchBlogs(); }, [currentPage]);

  const fetchBlogs = () => {
    fetch(`${baseUrl}/api/blogs?page=${currentPage}`)
      .then(response => {
        if (!response.ok) {
          // Handle server errors
          setServerError(true);
          setLoading(false);
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(data => {
        // Simulate a timeout after 10 seconds
        setTimeout(() => {
          if (loading) {
            setTimeoutError(true);
            setLoading(false);
          }
        }, 1000);
        
        // Handle successful response
        if (data.data) { setBlogs(data.data); }
        else { setBlogs(data); }
        setTotalPages(data.last_page);
        setTotal(data.total);
        setLoading(false);
      })
      .catch(error => {
        // console.error('Error fetching blogs:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  if (loading) {
    return <div>
      <LoadingSpinner />
      <LoadingSpinnerPagination />
    </div> 
  }

  if (error) {
    if (error.includes('404')) {
      return <NotFound />;
    } else if (error.includes('500')) {
      return <ErrorServer />;
    } else {
      return <ErrorGeneral />;
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="flex flex-wrap">
        {blogs.map((blog, index) => (
          <BlogCard
            key={index}
            id={blog.id}
            imageUrl={blog.imageUrl}
            title={blog.title}
            category={blog.category}
            readTime={blog.readTime}
            publishDate={blog.publishDate}
            likeCount={blog.likeCount}
            commentCount={blog.commentCount}
            isNew={blog.isNew}
            isHot={blog.isHot}
            tag1={blog.tag1}
            tag2={blog.tag2}
            tag3={blog.tag3}
            tag4={blog.tag4}
            tag5={blog.tag5}
            tag6={blog.tag6}
            tag7={blog.tag7}
            tag8={blog.tag8}
            link={blog.link}
            description={blog.description}
          />
        ))}
      </div>
      <div class="flex flex-col items-center bg-gray-100 dark:bg-gray-900 py-4 rounded-xl">
        <span class="text-sm text-gray-700 dark:text-gray-400">
            Current Page: <span class="font-semibold text-gray-900 dark:text-white">{currentPage}</span>
        </span>
        <div class="inline-flex mb-2 mt-2 xs:mt-0">
          <button onClick={handlePrevPage} disabled={currentPage === 1} class="flex items-center justify-center px-8 h-10 text-base font-medium text-gray-900 dark:text-gray-100 disabled:text-gray-500 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 disabled:hover:bg-gray-200 dark:hover:bg-gray-700 dark:disabled:hover:bg-gray-800 overflow-hidden transition-all duration-300 rounded-s">
            <IoIosArrowBack  className="h-5 w-5 mr-1" />Prev
          </button>
          <button onClick={handleNextPage} disabled={currentPage === totalPages} class="flex items-center justify-center px-8 h-10 text-base font-medium text-gray-900 dark:text-gray-100 disabled:text-gray-500 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 disabled:hover:bg-gray-200 dark:hover:bg-gray-700 dark:disabled:hover:bg-gray-800 overflow-hidden transition-all duration-300 rounded-e">
            Next<IoIosArrowForward  className="h-5 w-5 ml-1" />
          </button>
        </div>
        <span className="flex items-center text-sm text-gray-700 dark:text-gray-400">
          <FaInfoCircle className="h-5 w-5 mr-1" />
          Entries Per Page: <span className="font-semibold text-gray-900 dark:text-white ml-1">4</span>, Total Entries: <span className="font-semibold text-gray-900 dark:text-white ml-1">{total}</span>
        </span>
      </div>
    </div>
  );
}

import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CameraIcon, DevicePhoneMobileIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";

export default function Modal({ isOpen, onClose, imageDetails }) {
  const { id, gallery_id, imageUrl, title, imageType, gallery, camera, cameraType, formatted_date, dimensions, software } = imageDetails || {};

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-100 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <div className="h-full overflow-y-auto bg-gray-100 dark:bg-gray-900 p-8 border-l-2 border-gray-300 dark:border-gray-700">
                    <div className="space-y-6 pb-16">
                      <div>
                        <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
                          <img
                            src={imageUrl}
                            alt={title}
                            className="object-cover"
                          />
                        </div>
                        <div className="mt-4 flex items-start justify-between">
                          <div>
                            <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                              <span className="sr-only">Details for </span>{title !== null ? title : '-'}
                            </h2>
                            <p className="text-sm font-medium text-gray-400 mt-2 mb-3">Image Type: <span class="bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 rounded ml-1">{imageType !== null ? imageType : '-'}</span></p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h3 className="font-medium text-gray-900 dark:text-gray-100">Image Information</h3>
                        <dl className="mt-2 divide-y divide-gray-200 dark:divide-gray-800 border-t border-b border-gray-200 dark:border-gray-800">
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-400 py-1">Gallery</dt>
                            <dd className="text-gray-900 dark:text-gray-100 py-1"><span class="bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-300 text-sm font-medium px-4 py-2 rounded-md">{gallery !== null ? gallery : '-'}</span></dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-400">Camera</dt>
                            <dd className="text-gray-900 dark:text-gray-100">{camera !== null ? camera : '-'}</dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-400">Camera Type</dt>
                            <dd className="text-gray-900 dark:text-gray-100">
                              <div className="flex items-center">
                                <dt className="text-gray-900 dark:text-gray-100">{cameraType !== null ? cameraType : '-'} •&nbsp;</dt>
                                {cameraType === 'Mobile' ? (
                                  <DevicePhoneMobileIcon className="h-5 w-5" />
                                ) : cameraType === 'DSLR' ? (
                                  <CameraIcon className="h-5 w-5" />
                                ) : (
                                  // Add more conditions as needed
                                  null // or provide a default icon or null if none matches
                                )}
                              </div>
                            </dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-400">Capture Date</dt>
                            <dd className="text-gray-900 dark:text-gray-100">{formatted_date !== null ? formatted_date : '-'}</dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-400">Dimensions</dt>
                            <dd className="text-gray-900 dark:text-gray-100">{dimensions !== null ? dimensions : '-'}</dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-400">Software</dt>
                            <dd className="text-gray-900 dark:text-gray-100">{software !== null ? software : '-'}</dd>
                          </div>
                        </dl>
                      </div>
                      <br />
                      <div className="flex">
                        <Link to={`/portfolio/gallery/${gallery_id}/image/${id}`} class="inline-flex items-center px-10 py-2 text-sm font-medium text-center text-gray-900 dark:text-gray-100 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 overflow-hidden transition-all duration-300 rounded-md">
                          View Full Details
                          <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2 hidden md:block"/>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
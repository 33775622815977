import { TbError404 } from "react-icons/tb";

export default function NotFound() {
    return (
        <div class="text-center bg-gray-100 dark:bg-gray-900 py-24 rounded-xl mb-2">
            <div role="status">
                <TbError404 class="inline w-16 h-16 text-sky-500"/>
                <p class="text-md font-normal lg:text-lg text-gray-500 dark:text-gray-400">No Contents Found, for Now...</p>
            </div>
        </div>
    )
}
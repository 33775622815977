// IMPORTS : EXTERNAL
import { Link } from 'react-router-dom';
import { PhotoIcon, PaintBrushIcon, GlobeAltIcon, CursorArrowRaysIcon } from '@heroicons/react/20/solid';

const portfolio = [
  {
    title: 'Image Gallery',
    href: '/portfolio/gallery',
    icon: PhotoIcon,
    iconForeground: 'text-gray-950 dark:text-sky-100',
    iconBackground: 'bg-gray-200 dark:bg-sky-950',
    description: "Explore a curated collection of moments frozen in time. Each image narrates a unique story, capturing the beauty found in the ordinary.",
  },
  {
    title: 'Visual Identities',
    href: '/portfolio/visualidentities',
    icon: PaintBrushIcon,
    iconForeground: 'text-gray-950 dark:text-sky-100',
    iconBackground: 'bg-gray-200 dark:bg-sky-950',
    description: "Witness the fusion of design and narrative in visual identities. Logos are more than symbols; they are gateways to a brand's personality.",
  },
  {
    title: 'Websites',
    href: '/portfolio/websites',
    icon: GlobeAltIcon,
    iconForeground: 'text-gray-950 dark:text-sky-100',
    iconBackground: 'bg-gray-200 dark:bg-sky-950',
    description: "Navigate through seamless digital canvases where aesthetics meet usability. Each click unfolds an interactive story designed to leave a lasting impression.",
  },
  {
    title: 'Coming Soon!',
    // href: '#',
    icon: CursorArrowRaysIcon,
    iconForeground: 'text-gray-950 dark:text-sky-100',
    iconBackground: 'bg-gray-200 dark:bg-sky-950',
    description: 'New Sections are Coming Soon!',
  },
  // {
  //   title: 'Submit an expense',
  //   href: '#',
  //   icon: ReceiptRefundIcon,
  //   iconForeground: 'text-rose-700',
  //   iconBackground: 'bg-rose-50',
  // },
  // {
  //   title: 'Training',
  //   href: '#',
  //   icon: AcademicCapIcon,
  //   iconForeground: 'text-indigo-700',
  //   iconBackground: 'bg-indigo-50',
  // },
]

export default function PortfolioList() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div>
      <h1 class="mb-4 text-2xl md:text-3xl lg:text-4xl leading-none text-gray-900 dark:text-gray-100 font-bold">Our Portfolio</h1>
      <div className='pb-12'>
        <div className="divide-y divide-gray-300 dark:divide-gray-700 overflow-hidden rounded-lg bg-gray-300 dark:bg-gray-700 sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {portfolio.map((section, sectionId) => (
            <div
              key={section.title}
              className={classNames(
                sectionId === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                sectionId === 1 ? 'sm:rounded-tr-lg' : '',
                sectionId === portfolio.length - 2 ? 'sm:rounded-bl-lg' : '',
                sectionId === portfolio.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                'relative group bg-gray-100 dark:bg-gray-900 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-500'
              )}
            >
              <div>
                <span className={ classNames( section.iconBackground, section.iconForeground, 'rounded-lg inline-flex py-3 px-16' )}>
                  <section.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-4">
                <h3 className="text-xl font-medium text-gray-900 dark:text-gray-100">
                  <Link to={section.href} className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    <h2 className="text-2xl text-gray-900 dark:text-gray-100 font-bold">{section.title}</h2>
                  </Link>
                </h3>
                <p class="mt-1 text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400">{section.description}</p>
              </div>
              <span className="pointer-events-none absolute top-6 right-6 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500" aria-hidden="true">
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div> 
  );
}

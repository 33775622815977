// IMPORTS : INTERNAL
import ErrorServer from './ErrorServer';
import ErrorGeneral from './ErrorGeneral';
import LoadingSpinner from './LoadingSpinner';
import NotFound from './NotFound';
import ContentChangeLogTypeFilter from './ContentChangeLogTypeFilter';
import ContentChangeLogElement from './ContentChangeLogElement';

// IMPORTS : EXTERNAL
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCode } from "react-icons/fa6";
import { IoBug } from "react-icons/io5";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { IoColorPaletteSharp } from "react-icons/io5";
import { PiLinkSimpleBold } from "react-icons/pi";
import { SparklesIcon, FireIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ContentChangeLogList() {
  const [serverError, setServerError] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);
  const [changelogs, setChangelogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterType, setFilterType] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  
  useEffect(() => { fetchChangeLogs(); }, [filterType]);

  const fetchChangeLogs = () => {
    setLoading(true);
    setError(null);

    fetch(`${baseUrl}/api/changelogs-contents${filterType ? `/${filterType}` : ''}`)
      .then(response => {
        if (!response.ok) {
          // Handle server errors
          setServerError(true);
          setLoading(false);
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(data => {
        // Simulate a timeout after 10 seconds
        setTimeout(() => {
          if (loading) {
            setTimeoutError(true);
            setLoading(false);
          }
        }, 1000);
        
        // Handle successful response
        setChangelogs(data);
        setLoading(false);
      })
      .catch(error => {
        // console.error('Error fetching blogs:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  const handleFilter = (type) => {
    console.log(type);
    setFilterType(type);
    console.log(filterType)
  };

  if (loading) {
    return <div>
      <LoadingSpinner />
    </div> 
  }

  if (error) {
    if (error.includes('404')) {
      return (
        <div>
          <ContentChangeLogTypeFilter filterType={filterType} handleFilter={handleFilter}/>
          <NotFound />
        </div>
      )
    } else if (error.includes('500')) {
      return <ErrorServer />;
    } else {
      return <ErrorGeneral />;
    }
  }

  return (
    <div>
      <ContentChangeLogTypeFilter filterType={filterType} handleFilter={handleFilter}/>
      <div className="flow-root bg-gray-100 dark:bg-gray-900 py-10 px-10 rounded-xl mb-2">
        <ul role="list" className="-mb-8">
          {changelogs.map((changelogItem, changelogItemIdx) => (
            <li key={changelogItem.id}>
              <div className="relative pb-8">
                {changelogItemIdx !== changelogs.length - 1 ? (
                  <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-300 dark:bg-gray-700" aria-hidden="true" />
                ) : null}
                <div className="relative flex items-start space-x-3">
                  <ContentChangeLogElement
                    // key={index}
                    type={changelogItem.type}
                    href={changelogItem.details.href}
                    name={changelogItem.details.name}
                    comment={changelogItem.comment}
                    date={changelogItem.date}
                    timestamp={changelogItem.timestamp}
                    version={changelogItem.details.version}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <br />
      </div>
    </div>

  );
}

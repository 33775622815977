import { Helmet } from 'react-helmet';
import NavBar from '../blocks/NavBar';
import DividerDown from '../blocks/DividerDown';
import DividerDot from '../blocks/DividerDot';
import ImageDescription from '../blocks/ImageDescription';
import Footer from '../blocks/Footer';
import BreadCrumb from '../blocks/BreadCrumb';
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../blocks/LoadingSpinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export default function ImageDetails() {
  const [imageDetails, setImageDetails] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { galleryId, imageId } = useParams();
  let pages = imageDetails
  ? [
      { name: '...', href: '/portfolio', current: false },
      { name: 'Gallery', href: '/portfolio/gallery', current: false },
      { name: `${imageDetails.gallery}`, href: `/portfolio/gallery/${galleryId}`, current: false },
      { name: `Photo ${imageId}`, href: `/portfolio/gallery/${galleryId}/image/${imageId}`, current: true },
    ]
  : [
    { name: 'Portfolio', href: '/portfolio', current: false },
    { name: 'Gallery', href: '/portfolio/gallery', current: false },
  ];

  useEffect(() => {
    console.log("Entered API Call !!");
    const fetchGallery = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/galleries/${galleryId}/images/${imageId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch gallery');
        }

        const data = await response.json();
        console.log(data.gallery_id)
        setImageDetails(data);
      } catch (error) {
        console.error('Error fetching gallery:', error.message);
      }
    };

    fetchGallery();
  }, []);

  return (
    <div className="ImageDetails">
      <Helmet><title>Winksen // Image Details</title></Helmet>
      <NavBar/>

      <div className="bg-gray-50 dark:bg-gray-950 pb-10">
        <div class="container mx-auto px-10 pt-5">

          {/* BreadCrumb */}
          <BreadCrumb pages={pages}/>

          <DividerDot/>
          <br />

          {imageDetails ? (
            <div>
              <figure class="">
                <LazyLoadImage effect="opacity" className="h-full w-full rounded-lg" src={imageDetails.imageUrl} alt={imageDetails.alt}/>
                {/* <figcaption class="mt-2 text-sm text-center text-gray-400">{imageDetails.title}</figcaption> */}
              </figure>
              <br />
              <ImageDescription
                title={imageDetails.title}
                alt={imageDetails.alt}
                gallery={imageDetails.gallery}
                galleryId={imageDetails.gallery_id}
                formatted_date={imageDetails.formatted_date}
                location={imageDetails.location}
                coordinates={imageDetails.coordinates}
                dimensions={imageDetails.dimensions}
                size={imageDetails.size}
                imageType={imageDetails.imageType}
                fileName={imageDetails.fileName}
                camera={imageDetails.camera}
                lens={imageDetails.lens}
                cameraType={imageDetails.cameraType}
                focalLength={imageDetails.focalLength}
                shutterSpeed={imageDetails.shutterSpeed}
                aperture={imageDetails.aperture}
                iso={imageDetails.iso}
                software={imageDetails.software}
                rating={imageDetails.rating}
              />
            </div>
          ) : (
            <LoadingSpinner/>
          )}

          <Footer/>

        </div>
      </div>
    </div>
  )
}
// IMPORTS : EXTERNAL
import { MdTextSnippet } from "react-icons/md";
import { PhotoIcon, PaintBrushIcon, GlobeAltIcon } from '@heroicons/react/20/solid';
import { HiPaintBrush } from "react-icons/hi2";

export default function ContentChangeLogTypeFilter({ filterType, handleFilter }) {
    return (
        <div>
            <button className={`inline-flex mr-2 mb-2 items-center px-5 py-2 text-sm font-medium text-center  ${filterType === '' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('')}>ALL</button>
            <button className={`inline-flex mr-2 mb-2 items-center px-5 py-2 text-sm font-medium text-center ${filterType === 'blog' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('blog')}><MdTextSnippet className='w-3.5 h-3.5 mr-2'/> Blogs</button>
            <button className={`inline-flex mr-2 mb-2 items-center px-5 py-2 text-sm font-medium text-center ${filterType === 'gallery' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('gallery')}><PhotoIcon className='w-3.5 h-3.5 mr-2'/> Galleries</button>
            <button className={`inline-flex mr-2 mb-2 items-center px-5 py-2 text-sm font-medium text-center ${filterType === 'visualIdentity' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('visualIdentity')}><HiPaintBrush className='w-3.5 h-3.5 mr-2'/> Visual Identities</button>
            <button className={`inline-flex mb-2 items-center px-5 py-2 text-sm font-medium text-center ${filterType === 'website' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('website')}><GlobeAltIcon className='w-3.5 h-3.5 mr-2'/> Websites</button>
        </div>
    )
}
import { useState } from 'react';
import LoadingSpinnerImage from './LoadingSpinnerImage';

export default function ImageRenderer({image, index}) {
  const [loading, setLoading] = useState(true);
  console.log(image)

  return (
    <div>
        <img className="h-24 sm:h-40 md:h-60 lg:h-80 w-full object-cover rounded-md lg:rounded-lg" src={image} alt={`Image ${index}`} style={{ display: loading ? "none" : "block", }} onLoad={(e)=>{setLoading(false)}}></img>
        <div className="h-24 sm:h-40 md:h-60 lg:h-80 w-full object-cover rounded-md lg:rounded-lg" style={{ display: loading ? "block" : "none", }} >
            <LoadingSpinnerImage />
        </div>
    </div>
  )
}
import { useState, useEffect } from 'react';
import ErrorServerFeatured from './ErrorServerFeatured';
import ErrorGeneralFeatured from './ErrorGeneralFeatured';
import NotFoundFeatured from './NotFoundFeatured';
import BlogCardFeatured from './BlogCardFeatured';
import LoadingSpinnerFeatured from './LoadingSpinnerFeatured';

export default function BlogFeatured() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);
  const [featuredBlog, setFeaturedBlog] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => { fetchFeaturedBlog(); }, []);

  const fetchFeaturedBlog = () => {
    fetch(`${baseUrl}/api/blogs/featured`)
      .then(response => {
        if (!response.ok) {
          // Handle server errors
          setServerError(true);
          setLoading(false);
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(data => {
        // Simulate a timeout after 10 seconds
        setTimeout(() => {
          if (loading) {
            setTimeoutError(true);
            setLoading(false);
          }
        }, 1000);
        
        // Handle successful response
        if (data) { setFeaturedBlog(data); }
        setLoading(false);
      })
      .catch(error => {
        // console.error('Error fetching blogs:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  if (loading) {
    return <div>
      <LoadingSpinnerFeatured />
    </div> 
  }

  if (error) {
    if (error.includes('404')) {
      return <NotFoundFeatured />;
    } else if (error.includes('500')) {
      return <ErrorServerFeatured />;
    } else {
      return <ErrorGeneralFeatured />;
    }
  }

  return (
    <div>
      <div className="container">
        <BlogCardFeatured
          id={featuredBlog.id}
          imageUrl={featuredBlog.imageUrl}
          title={featuredBlog.title}
          category={featuredBlog.category}
          readTime={featuredBlog.readTime}
          publishDate={featuredBlog.publishDate}
          likeCount={featuredBlog.likeCount}
          commentCount={featuredBlog.commentCount}
          isNew={featuredBlog.isNew}
          isHot={featuredBlog.isHot}
          tag1={featuredBlog.tag1}
          tag2={featuredBlog.tag2}
          tag3={featuredBlog.tag3}
          tag4={featuredBlog.tag4}
          tag5={featuredBlog.tag5}
          tag6={featuredBlog.tag6}
          tag7={featuredBlog.tag7}
          tag8={featuredBlog.tag8}
          link={featuredBlog.link}
          description={featuredBlog.description}
        />
      </div>
    </div>
  );
}

import NavBar from '../blocks/NavBar';
import { Helmet } from 'react-helmet';
import '../App.css';
import IdentityCard from '../blocks/IdentityCard';
import BreadCrumb from '../blocks/BreadCrumb';
import DividerDown from '../blocks/DividerDown';
import DividerDot from '../blocks/DividerDot';
import Modal from '../blocks/Modal';
import Footer from '../blocks/Footer';
import LoadingSpinner from '../blocks/LoadingSpinner'
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SparklesIcon, FireIcon } from '@heroicons/react/20/solid'
import { MdLandscape, MdHistoryEdu, MdOutlineUnfoldMore, MdOutlineUnfoldLess } from "react-icons/md";
import { TiGlobe, TiGroup  } from "react-icons/ti";
import { FaImagePortrait } from "react-icons/fa6";
import { PiBowlFoodFill } from "react-icons/pi";
import { BiSolidCat } from "react-icons/bi";
import { GiAbstract086 } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendarMinus } from "react-icons/fa6";
import { TbMacro } from "react-icons/tb";
import { BsSymmetryVertical } from "react-icons/bs";
import { FaTreeCity } from "react-icons/fa6";
import { VscColorMode } from "react-icons/vsc";
import { FaDraftingCompass } from "react-icons/fa";
import { MdNightlight } from "react-icons/md";
import { IoIosBowtie } from "react-icons/io";
import { FaCity } from "react-icons/fa6";
import { PiSunHorizonFill } from "react-icons/pi";
import { MdAgriculture } from "react-icons/md";
import { FaBridgeWater } from "react-icons/fa6";
import { TbTimelineEventFilled } from "react-icons/tb";
import { MdStars } from "react-icons/md";
import { HiMiniMap } from "react-icons/hi2";
import { BsFillFileImageFill } from "react-icons/bs";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaHandsHoldingCircle } from "react-icons/fa6";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import ImageRenderer from '../blocks/ImageRenderer';
import { PiBirdFill } from "react-icons/pi";
import { FaHouseFloodWater } from "react-icons/fa6";

function IdentityDetails() {
  const [identity, setIdentity] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [visibleImages, setVisibleImages] = useState(6);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);
  const [identityOthers, setIdentityOthers] = React.useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { identityId } = useParams();
  
  let pages = identity
  ? [
      { name: 'Portfolio', href: '/portfolio', current: false },
      { name: 'Identity', href: '/portfolio/visualidentities', current: false },
      { name: `${identity.title}`, href: `/portfolio/visualidentities/${identityId}`, current: true },
    ]
  : [
      { name: 'Portfolio', href: '/portfolio', current: false },
      { name: 'Identity', href: '/portfolio/identity', current: false },
    ];

  useEffect(() => {
    console.log("Entered API Call !!");
    const fetchIdentity = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/identities/${identityId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch identity');
        }

        const data = await response.json();
        setIdentity(data);
        setImages(data.images);
      } catch (error) {
        console.error('Error fetching identity:', error.message);
      }
    };

    fetchIdentity();
  }, [identityId]);

  useEffect(() => {
    console.log("Entered API Call !!");
    const fetchBlogOthers = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/identities/${identityId}/others`);
        if (!response.ok) {
          throw new Error('Failed to fetch other identities');
        }

        const data = await response.json();
        setIdentityOthers(data);
      } catch (error) {
        console.error('Error fetching identity:', error.message);
      }
    };

    fetchBlogOthers();
  }, [identityId]);

  const openModal = (imageDetails) => {
    console.log(imageDetails);
    setModalData(imageDetails);
    setIsModalOpen(true);
  };
  
  // const navigate = useNavigate();
  // Check if the identityName is valid (you can implement your own logic here)
  // useEffect(() => {
  //   const isValidIdentity = isValidIdentityName(identityName);

  //   if (!isValidIdentity) {
  //     // Use navigate to redirect to 404 if the identityName is not valid
  //     navigate('/404');
  //   }
  // }, [identityName, navigate]);

  // if (!isValidIdentityName(identityName)) {
  //   // Returning null to avoid rendering the component
  //   return null;
  // }

  return (
    <div className="IdentityDetails">
      <Helmet><title>Winksen // Identity Details</title></Helmet>
      <NavBar/>
      <div className="bg-gray-50 dark:bg-gray-950 pb-10">
        <div className="container mx-auto px-10 pt-5">

          {/* BreadCrumb */}
          <BreadCrumb pages={pages}/>

          <DividerDot/>
          <br />
          
          {identity ? (
            <div>
              <div className="flex items-center">
                <h1 class="mb-2 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl ">Visual Identity: <span class="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">{identity.title}</span></h1>
              </div>
              <div className="mb-2 flex items-center flex-row">
                {!!identity.isFeatured && (<span className="flex items-center bg-gradient-to-r from-blue-600 to-blue-500 text-blue-100 text-md font-medium px-8 py-0.5 rounded-md mr-2"><MdStars className="h-5 w-5 mr-1"/>FEATURED</span>)}
                {!!identity.isNew && (<span className="flex items-center bg-gradient-to-r from-emerald-600 to-emerald-500 text-emerald-100 text-md font-medium px-4 py-0.5 rounded-full mr-2"><SparklesIcon className="h-5 w-5 mr-1"/>NEW</span>)}
                {!!identity.isHot && (<span className="flex items-center bg-gradient-to-r from-red-600 to-red-500 text-red-100 text-md font-medium px-4 py-0.5 rounded-full mr-2"><FireIcon className="h-5 w-5 mr-1"/>HOT</span>)}
              </div>
              {/* <p className='text-gray-500 dark:text-gray-400 text-lg lg:text-xl font-medium mb-2'>Identity Details:</p> */}
              <div className="text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400 rounded-lg">
                <div className="flex items-center"> 
                  <FaCalendarMinus className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>
                  <span>{identity.formatted_date}</span>
                </div>
              </div>
              <div className="flex flex-wrap items-center mb-2">
                {!!identity.tag1 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><TiGlobe className="h-5 w-5 mr-1"/>Travel</span>)}
                {!!identity.tag2 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><MdLandscape className="h-5 w-5 mr-1"/>Landscape</span>)}
                {!!identity.tag3 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><FaImagePortrait className="h-4 w-4 mr-1"/>Portrait</span>)}
                {!!identity.tag4 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><TiGroup className="h-5 w-5 mr-1"/>Culture</span>)}
                {!!identity.tag5 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><PiBowlFoodFill className="h-5 w-5 mr-1"/>Food</span>)}
                {!!identity.tag6 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><BiSolidCat className="h-5 w-5 mr-1"/>Wildlife</span>)}
                {!!identity.tag7 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><GiAbstract086 className="h-5 w-5 mr-1"/>Abstract</span>)}
                {!!identity.tag8 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><MdHistoryEdu className="h-5 w-5 mr-1"/>Historical</span>)}
                {!!identity.tag9 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><TbMacro className="h-5 w-5 mr-1"/>Macro</span>)}
                {!!identity.tag10 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><BsSymmetryVertical className="h-5 w-5 mr-1"/>Symmetry</span>)}
              </div>
              <p className="text-lg lg:text-xl font-normal text-gray-500 dark:text-gray-400">{identity.description}</p>
              <p className="text-lg lg:text-xl font-normal text-gray-500 dark:text-gray-400">Website: {identity.website}</p>
              <br />

              <p className="text-lg lg:text-xl font-normal text-gray-700 dark:text-gray-300 mb-2"><b>• Logos:</b></p>
              <div className='grid grid-cols-2 lg:grid-cols-4 gap-2'>
                {identity.logo && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Main Logo: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logo} alt="Main Logo" />
                  </div>
                )}
                {identity.logoMark && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Main LogoMark: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoMark} alt="Main LogoMark" />
                  </div>
                )}

                {identity.logoSecondary1 && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Secondary 1 Logo: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoSecondary1} alt="Secondary 1 Logo" />
                  </div>
                )}
                {identity.logoMarkSecondary1 && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Secondary 2 LogoMark: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoMarkSecondary1} alt="Secondary 2 LogoMark" />
                  </div>
                )}

                {identity.logoSecondary2 && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Secondary 2 Logo: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoSecondary2} alt="Secondary 2 Logo" />
                  </div>
                )}
                {identity.logoMarkSecondary2 && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Secondary 2 LogoMark: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoMarkSecondary2} alt="Secondary 2 LogoMark" />
                  </div>
                )}

                {identity.logoSecondary3 && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Secondary 3 Logo: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoSecondary3} alt="Secondary 3 Logo" />
                  </div>
                )}
                {identity.logoMarkSecondary3 && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Secondary 3 LogoMark: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoMarkSecondary3} alt="Secondary 3 LogoMark" />
                  </div>
                )}

                {identity.logoSecondary4 && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Secondary 4 Logo: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoSecondary4} alt="Secondary 4 Logo" />
                  </div>
                )}
                {identity.logoMarkSecondary4 && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">Secondary 4 LogoMark: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoMarkSecondary4} alt="Secondary 4 LogoMark" />
                  </div>
                )}

                {identity.logoBW && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">B&W Logo: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoBW} alt="B&W Logo" />
                  </div>
                )}
                {identity.logoMarkBW && (
                  <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg '>
                    <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2 text-center">B&W LogoMark: </p>
                    <img className="object-cover rounded-md lg:rounded-lg" src={identity.logoMarkBW} alt="B&W LogoMark" />
                  </div>
                )}
              </div>
              <br />
              
              <p className="text-lg lg:text-xl font-normal text-gray-700 dark:text-gray-300 mb-2"><b>• Typography:</b></p>
              <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2">Main Font: {identity.typography}</p>
              {identity.typographyImage && (
                <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg flex justify-center mb-4'>
                  <img className="object-cover rounded-md lg:rounded-lg" src={identity.typographyImage} alt="Main Logo" />
                </div>
              )}
              <p className="text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-2">Secondary Font: {identity.typography}</p>
              {identity.typographyImage && (
                <div className='p-3 bg-gray-200 dark:bg-gray-800 rounded-md lg:rounded-lg flex justify-center'>
                  <img className="object-cover rounded-md lg:rounded-lg" src={identity.typographyImage} alt="Main Logo" />
                </div>
              )}
              <br />
    
    
              <DividerDot/>
              <br />
              
              <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Explore More <span className="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">Stunning </span> Identities.</h1>
              <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">View more beautiful Identities.</p>
              <br />

              {identityOthers ? (
              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:gap-4">
                {identityOthers.map((identity, index) => (
                  <IdentityCard
                    key={index}
                    imageUrl={identity.imageUrl}
                    title={identity.title}
                    location={identity.location}
                    formatted_date={identity.formatted_date}
                    images_count={identity.images_count}
                    isNew={identity.isNew}
                    isHot={identity.isHot}
                    tag1={identity.tag1}
                    tag2={identity.tag2}
                    tag3={identity.tag3}
                    tag4={identity.tag4}
                    tag5={identity.tag5}
                    tag6={identity.tag6}
                    tag7={identity.tag7}
                    tag8={identity.tag8}
                    tag9={identity.tag9}
                    tag10={identity.tag10}
                    tag11={identity.tag11}
                    tag12={identity.tag12}
                    tag13={identity.tag13}
                    tag14={identity.tag14}
                    tag15={identity.tag15}
                    tag16={identity.tag16}
                    tag17={identity.tag17}
                    tag18={identity.tag18}
                    tag19={identity.tag19}
                    tag20={identity.tag20}
                    id={identity.id}
                    description={identity.description}
                  />
                ))}
              </div>
              ) : (
                <LoadingSpinner />
              )}
            </div>
          ) : (
            <LoadingSpinner />
          )}
          
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default IdentityDetails;

// IMPORTS : INTERNAL
import ErrorServer from './ErrorServer';
import ErrorGeneral from './ErrorGeneral';
import LoadingSpinner from './LoadingSpinner';
import LoadingSpinnerPagination from './LoadingSpinnerPagination';
import NotFound from './NotFound';
import IdentityCard from './IdentityCard';

// IMPORTS : EXTERNAL
import { useState, useEffect } from 'react';
import { FaInfoCircle } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function IdentityList() {
  const [serverError, setServerError] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);
  const [identities, setIdentities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(1);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => { fetchIdentities(); }, [currentPage]);

  const fetchIdentities = () => {
    fetch(`${baseUrl}/api/identities?page=${currentPage}`)
      .then(response => {
        if (!response.ok) {
          // Handle server errors
          setServerError(true);
          setLoading(false);
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(data => {
        // Simulate a timeout after 10 seconds
        setTimeout(() => {
          if (loading) {
            setTimeoutError(true);
            setLoading(false);
          }
        }, 1000);
        
        // Handle successful response
        if (data.data) { setIdentities(data.data); }
        else { setIdentities(data); }
        setTotalPages(data.last_page);
        setTotal(data.total);
        setLoading(false);
      })
      .catch(error => {
        // console.error('Error fetching blogs:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  if (loading) {
    return <div>
      <LoadingSpinner />
      <LoadingSpinnerPagination />
    </div> 
  }

  if (error) {
    if (error.includes('404')) {
      return <NotFound />;
    } else if (error.includes('500')) {
      return <ErrorServer />;
    } else {
      return <ErrorGeneral />;
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-4">
        {identities.map((identity, index) => (
          <IdentityCard
            key={index}
            logo={identity.logo}
            logoMark={identity.logoMark}
            logoSecondary1={identity.logoSecondary1}
            logoMarkSecondary1={identity.logoMarkSecondary1}
            logoSecondary2={identity.logoSecondary2}
            logoMarkSecondary2={identity.logoMarkSecondary2}
            logoSecondary3={identity.logoSecondary3}
            logoMarkSecondary3={identity.logoMarkSecondary3}
            logoSecondary4={identity.logoSecondary4}
            logoMarkSecondary4={identity.logoMarkSecondary4}
            logoBW={identity.logoBW}
            logoMarkBW={identity.logoMarkBW}
            typography={identity.typography}
            typographyImage={identity.typographyImage}
            mockup1={identity.mockup1}
            mockup2={identity.mockup2}
            mockup3={identity.mockup3}
            mockup4={identity.mockup4}
            mockup5={identity.mockup5}
            mockup6={identity.mockup6}
            mockup7={identity.mockup7}
            title={identity.title}
            formatted_date={identity.formatted_date}
            images_count={identity.images_count}
            isNew={identity.isNew}
            isHot={identity.isHot}
            tag1={identity.tag1}
            tag2={identity.tag2}
            tag3={identity.tag3}
            tag4={identity.tag4}
            tag5={identity.tag5}
            tag6={identity.tag6}
            tag7={identity.tag7}
            tag8={identity.tag8}
            tag9={identity.tag9}
            tag10={identity.tag10}
            id={identity.id}
            description={identity.description}
          />
        ))}
      </div>
      <div class="flex flex-col items-center bg-gray-100 dark:bg-gray-900 py-4 rounded-xl">
        <span class="text-sm text-gray-700 dark:text-gray-400">
            Current Page: <span class="font-semibold text-gray-900 dark:text-white">{currentPage}</span>
        </span>
        <div class="inline-flex mb-2 mt-2 xs:mt-0">
          <button onClick={handlePrevPage} disabled={currentPage === 1} class="flex items-center justify-center px-8 h-10 text-base font-medium text-gray-900 dark:text-gray-100 disabled:text-gray-500 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 disabled:hover:bg-gray-200 dark:hover:bg-gray-700 dark:disabled:hover:bg-gray-800 overflow-hidden transition-all duration-300 rounded-s">
            <IoIosArrowBack  className="h-5 w-5 mr-1" />Prev
          </button>
          <button onClick={handleNextPage} disabled={currentPage === totalPages} class="flex items-center justify-center px-8 h-10 text-base font-medium text-gray-900 dark:text-gray-100 disabled:text-gray-500 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 disabled:hover:bg-gray-200 dark:hover:bg-gray-700 dark:disabled:hover:bg-gray-800 overflow-hidden transition-all duration-300 rounded-e">
            Next<IoIosArrowForward  className="h-5 w-5 ml-1" />
          </button>
        </div>
        <span className="flex items-center text-sm text-gray-700 dark:text-gray-400">
          <FaInfoCircle className="h-5 w-5 mr-1" />
          Entries Per Page: <span className="font-semibold text-gray-900 dark:text-white ml-1">3</span>, Total Entries: <span className="font-semibold text-gray-900 dark:text-white ml-1">{total}</span>
        </span>
      </div>
    </div>
  );
}

// IMPORTS : INTERNAL
import NavBar from '../blocks/NavBar';
import BreadCrumb from '../blocks/BreadCrumb';
import Footer from '../blocks/Footer';
import DividerDot from '../blocks/DividerDot';
import BlogList from '../blocks/BlogList';
import BlogFeatured from '../blocks/BlogFeatured';

// IMPORTS : EXTERNAL
import { Helmet } from 'react-helmet';

const pages = [
  { name: 'Blog', href: '/blog', current: true },
]

function Blog() {
  return (
    <div className="Blog">
      <Helmet><title>Winksen // Blog</title></Helmet>
      <NavBar/>

      <body className="bg-gray-50 dark:bg-gray-950 pb-10">
        <div class="container mx-auto px-10 pt-5">

          {/* BreadCrumb */}
          <BreadCrumb pages={pages}/>

          <DividerDot/>
          <br />
          
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl">Discover Our <span class="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">Blog</span>!</h1>
          <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400">Dive into a world where every frame tells a story. Explore OUR Image Gallery, a curated collection of captivating visuals that freeze moments in time. From the play of light to the raw emotions, each image is a testament to the art of photography and the beauty found in the ordinary.</p>
          <br />

          <div className="container">
            <BlogFeatured/>
            <BlogList />
          </div>

          <Footer/>
        </div>
      </body>
    </div>
  );
}

export default Blog;
import { IoIosArrowForward } from "react-icons/io";
import { HiMiniBellAlert } from "react-icons/hi2";
import NewsLetterForm from "./NewsLetterForm";

export default function NewsLetter() {
  return (
    <div>
      <h1 class="mb-4 text-2xl md:text-3xl lg:text-4xl leading-none text-gray-900 dark:text-gray-100 font-bold">NewsLetter</h1>  
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="py-14 px-4 bg-gray-100 dark:bg-gray-900 rounded-lg">
          <div className="text-center">
            <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 dark:text-gray-100">Sign up for our NewsLetter</h2>
            <p class="mt-2 text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400">
              Keep up with the latest Offers, Updates and Improvements from us!
            </p>
          </div>
          <div className="mt-8 text-center">
            <NewsLetterForm/>
            {/* <p className="mt-3 text-sm text-gray-500">
              We care about the protection of your data. Read our{' '}
              <a href="#" className="font-medium underline">
                Privacy Policy.
              </a>
            </p> */}
          </div>
        </div>
        <div className="py-14 px-4 bg-gray-100 dark:bg-gray-900 rounded-lg">
          <div className="text-center">
            <span className='inline-flex'>
              <HiMiniBellAlert className="w-16 h-16 text-sky-600 dark:text-sky-500 text-center"/>
            </span>
            <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 dark:text-gray-100">Why Subscribe?</h2>
            <p class="mt-2 text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400">
              • Get Updates on regular improvements of our Website.
            </p>
            <p class="mt-2 text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400">
              • Be first to be notified when an Offer is live.
            </p>
            <p class="mt-2 text-md lg:text-lg font-normal text-gray-500 dark:text-gray-400">
              • and More!
            </p>
          </div>
        </div>
      </div>
    </div>
    )
  }
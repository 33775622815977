import { BiSolidLike } from "react-icons/bi";
import { FaCommentAlt } from "react-icons/fa";
import { HiNewspaper } from "react-icons/hi";
import { SparklesIcon, FireIcon } from '@heroicons/react/20/solid'
import { MdStars } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { PiVideoFill } from "react-icons/pi";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";
import { PiBookOpenFill } from "react-icons/pi";

function CardGallery({ id, imageUrl, title, category, readTime, publishDate, likeCount, commentCount, isNew, isHot, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, description, link }) {
  return (
    <div class="p-2 mb-2 bg-gray-50 dark:bg-gray-950">
      <Link to={`/blog/${id}`}>
        <div class="h-full bg-sky-700 rounded-xl overflow-hidden">
          <div className="relative">
            <img className="lg:h-96 md:h-72 w-full object-cover object-center" src={imageUrl} alt="blog" />
            <span className="flex items-center absolute top-3 right-5 bg-sky-700 dark:bg-sky-600 text-white text-lg font-medium px-2 py-0.5 rounded-full"><MdStars className="h-5 w-5 mr-1"/>FEATURED</span>
          </div>
          <div class="p-6">
            <div className="flex flex-wrap items-center mb-2">
              {category == 'News' && (<span className="flex items-center bg-sky-600 text-gray-100 text-sm font-medium me-2 px-10 py-1 rounded-md"><HiNewspaper className="h-5 w-5 mr-1"/>News</span>)}
              {category == 'Tutorials' && (<span className="flex items-center bg-sky-600 text-gray-100 text-sm font-medium me-2 px-10 py-1 rounded-md"><PiVideoFill  className="h-5 w-5 mr-1"/>Tutorials</span>)}
              {category == 'Docs' && (<span className="flex items-center bg-sky-600 text-gray-100 text-sm font-medium me-2 px-10 py-1 rounded-md"><HiMiniDocumentDuplicate  className="h-5 w-5 mr-1"/>Docs</span>)}
              {category == 'Stories' && (<span className="flex items-center bg-sky-600 text-gray-100 text-sm font-medium me-2 px-10 py-1 rounded-md"><PiBookOpenFill  className="h-5 w-5 mr-1"/>Stories</span>)}
            </div>
            <h5 class="mr-2 mb-2 text-2xl font-bold tracking-tight text-gray-100">{title}</h5>
            {/* <div className="mb-2 flex items-center flex-row">
              {!!isFeatured && (<span className="flex items-center bg-blue-500 text-white text-xs font-medium px-4 py-0.5 rounded-full mr-2"><MdStars className="h-4 w-4 mr-1"/>FEATURED</span>)}
              {!!isNew && (<span className="flex items-center bg-green-100 text-green-800 text-sm font-medium px-4 py-0.5 rounded-full mr-2"><SparklesIcon className="h-4 w-4 mr-1"/>NEW</span>)}
              {!!isHot && (<span className="flex items-center bg-red-100 text-red-800 text-sm font-medium px-4 py-0.5 rounded-full mr-2"><FireIcon className="h-4 w-4 mr-1"/>HOT</span>)}
            </div> */}
            <p class="leading-relaxed text-gray-300 mb-3">{description}</p>
            <div class="flex items-center flex-wrap ">
              <Link to={`/blog/${id}`} class="inline-flex items-center px-5 md:px-10 py-2 text-sm font-medium text-center text-gray-100 bg-gray-950 hover:bg-gray-900 overflow-hidden transition-all duration-300 rounded-md">
                Read More
                <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2 hidden md:block"/>
              </Link>
              {/* <p class="flex items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400"><BiSolidLike className="h-5 w-5 ml-1 mr-1 text-gray-600 dark:text-gray-300"/> {likeCount} • <FaCommentAlt className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/> {commentCount}</p> */}

              <span class="text-gray-300 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-md pr-3 py-1">
                <BiSolidLike className="h-5 w-5 text-gray-100"/>{likeCount} • <FaCommentAlt className="h-4 w-4 text-gray-100 mr-1 ml-1"/>{commentCount}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CardGallery;
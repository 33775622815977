import NavBar from '../blocks/NavBar';
import { Helmet } from 'react-helmet';
import '../App.css';
import BlogCard from '../blocks/BlogCard';
import BreadCrumb from '../blocks/BreadCrumb';
import DividerDown from '../blocks/DividerDown';
import DividerDot from '../blocks/DividerDot';
import Modal from '../blocks/Modal';
import Footer from '../blocks/Footer';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SparklesIcon, FireIcon } from '@heroicons/react/20/solid'
import { MdLandscape, MdHistoryEdu, MdOutlineUnfoldMore, MdOutlineUnfoldLess } from "react-icons/md";
import { TiGlobe, TiGroup  } from "react-icons/ti";
import { FaImagePortrait } from "react-icons/fa6";
import { PiBowlFoodFill } from "react-icons/pi";
import { BiSolidCat } from "react-icons/bi";
import { GiAbstract086 } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendarMinus } from "react-icons/fa6";
import { TbMacro } from "react-icons/tb";
import { BsSymmetryVertical } from "react-icons/bs";
import { FaTreeCity } from "react-icons/fa6";
import { VscColorMode } from "react-icons/vsc";
import { FaDraftingCompass } from "react-icons/fa";
import { MdNightlight } from "react-icons/md";
import { IoIosBowtie } from "react-icons/io";
import { FaCity } from "react-icons/fa6";
import { PiSunHorizonFill } from "react-icons/pi";
import { MdAgriculture } from "react-icons/md";
import { FaBridgeWater } from "react-icons/fa6";
import { TbTimelineEventFilled } from "react-icons/tb";
import { FaPen } from "react-icons/fa6";
import { FaHashtag } from "react-icons/fa";
import { HiNewspaper } from "react-icons/hi";
import { FaClock } from "react-icons/fa";
import Divider from '../blocks/Divider';
import { FaRegCommentDots } from "react-icons/fa";
import { BiLike } from "react-icons/bi";
import { BiSolidLike } from "react-icons/bi";
import { FaCommentAlt } from "react-icons/fa";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { MdStars } from "react-icons/md";
import LoadingSpinner from '../blocks/LoadingSpinner'
import NotFound from '../blocks/NotFound'
import { PiVideoFill } from "react-icons/pi";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";
import { PiBookOpenFill } from "react-icons/pi";
import ContentRenderer from '../blocks/ContentRenderer';

// Add your own logic to determine if a galleryName or imageId is valid
// const isValidGalleryName = (galleryName) => {
//     // Implement your own validation logic
//     return galleryName === 'malaysia-2023' || galleryName === 'france-2023';
// };

// const blog = {
//   id: '1',
//   imageUrl: '/images/Malaysia.jpg',
//   title: 'Why B&W Logos Should Be Optically Adjusted',
//   author: 'Amine Tabia',
//   category: 'News',
//   link: '/blog/1',
//   readTime: '12 min',
//   publishDate: 'August 25, 2024',
//   revisions: '12',
//   likeCount: '1.2K',
//   commentCount: '60',
//   isNew: true, isHot: true, isFeatured: true,
//   description: 'Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.',
//   content: [
//     {
//       type: 'paragraph',
//       content: [
//         'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in justo ',
//         <strong>faucibus</strong>,
//         ', ',
//         <em>laoreet</em>,
//         ' lectus nec, aliquet sapien. Ut vehicula est vitae enim ultrices, vitae consequat lorem tincidunt. Integer sed est nec neque eleifend dictum.'
//       ]
//     },
//     {
//       type: 'paragraph',
//       content: [
//         'Suspendisse potenti. Vestibulum scelerisque massa quis feugiat congue. Curabitur ',
//         <Link className='hover:text-sky-500 underline' to="https://example.com">interdum</Link>,
//         ' sollicitudin sem, vel vestibulum dui facilisis vel. Donec ',
//         <strong>aliquet</strong>,
//         ' ipsum eget velit mollis, eu ',
//         <em>condimentum</em>,
//         ' mauris ultrices.'
//       ]
//     },
//     {
//       type: 'quote',
//       content: 'Suspendisse potenti. Vestibulum scelerisque massa quis feugiat congue.'
//     },
//     {
//       type: 'table',
//       title: 'Table Title',
//       description: 'Browse a list of Flowbite products designed to help you work and play, stay organized, get answers, keep in touch, grow your business, and more.',
//       content: [
//         ['Header 1', 'Header 2', 'Header 3'],
//         ['Data 1', 'Data 2', 'Data 3'],
//         ['Data 4', 'Data 5', 'Data 6'],
//         ['Data 4', 'Data 5', 'Data 6'],
//         ['Data 4', 'Data 5', 'Data 6'],
//         ['Data 7', 'Data 8', 'Data 9']
//       ]
//     },
//     {
//       type: 'list',
//       title: 'List Title',
//       items: ['Donec at arcu eget urna fringilla vehicula.', 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.']
//     },
//     {
//       type: 'image',
//       imageUrl: '/images/Malaysia.jpg',
//       description: 'Image Description',
//       alt: 'alt'
//     },
//     {
//       type: 'quote',
//       content: 'Suspendisse potenti. Vestibulum scelerisque massa quis feugiat congue.'
//     },
//     {
//       type: 'list',
//       title: 'List Title',
//       items: ['Donec at arcu eget urna fringilla vehicula.', 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.']
//     },
//     {
//       type: 'image',
//       imageUrl: '/images/Malaysia.jpg',
//       description: 'Image Description',
//       alt: 'alt'
//     },
//   ]
// }

// const otherBlogs = [
//   {
//     id: '1', imageUrl: '/images/Malaysia.jpg', title: 'Malaysia 2023', category: 'News', link: '/blog/1', readTime: '', publishDate: '', likeCount: '1.2K', commentCount: '60',
//     isNew: true, isHot: true, tag1: true, tag2: false, tag3: true, tag4: false, tag5: false, tag6: false, tag7: false, tag8: false,
//     description: 'Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.'
//   },
//   {
//     id: '2', imageUrl: '/images/Malaysia2.jpg', title: 'Malaysia 2023', category: 'News', link: '/blog/1', readTime: '', publishDate: '', likeCount: '1.4K', commentCount: '55',
//     isNew: true, isHot: true, tag1: true, tag2: false, tag3: true, tag4: false, tag5: false, tag6: false, tag7: false, tag8: false,
//     description: 'Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.'
//   },
//   {
//     id: '3', imageUrl: '/images/Malaysia3.jpg', title: 'Malaysia 2023', category: 'News', link: '/blog/1', readTime: '', publishDate: '', likeCount: '1.5K', commentCount: '51',
//     isNew: true, isHot: true, tag1: true, tag2: false, tag3: true, tag4: false, tag5: false, tag6: false, tag7: false, tag8: false,
//     description: 'Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.'
//   },
// ]

function BlogDetails() {
  const [blog, setBlog] = React.useState(null);
  const [blogOthers, setBlogOthers] = React.useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { blogId } = useParams();
  
  let pages = blog
  ? [
      { name: 'Blog', href: '/blog', current: false },
      { name: `${blog.title}`, href: `/blog/${blogId}`, current: true },
    ]
  : [
      { name: 'Blog', href: '/blog', current: false },
    ];

  useEffect(() => {
    console.log("Entered API Call !!");
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/blogs/${blogId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch blog');
        }

        const data = await response.json();
        setBlog(data);
      } catch (error) {
        console.error('Error fetching blog:', error.message);
      }
    };

    fetchBlogDetails();
  }, [blogId]);


  useEffect(() => {
    console.log("Entered API Call !!");
    const fetchBlogOthers = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/blogs/${blogId}/others`);
        if (!response.ok) {
          throw new Error('Failed to fetch other blogs');
        }

        const data = await response.json();
        setBlogOthers(data);
      } catch (error) {
        console.error('Error fetching blog:', error.message);
      }
    };

    fetchBlogOthers();
  }, [blogId]);

  return (
    <div className="GalleryDetails">
      <Helmet><title>Winksen // Blog Details</title></Helmet>
      <NavBar/>
      <div className="bg-gray-50 dark:bg-gray-950 pb-10">
        <div className="container mx-auto px-10 pt-5">

          {/* BreadCrumb */}
          <BreadCrumb pages={pages}/>

          <DividerDot/>
          <br />
          
          {blog ? (
            <div>
              <div className="flex items-center">
                <h1 className="mb-2 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl">{blog.title}</h1>
              </div>
              <div className="mb-2 flex items-center flex-row">
                {!!blog.isFeatured && (<span className="flex items-center bg-blue-500 text-white text-md font-medium px-4 py-0.5 rounded-md mr-2"><MdStars className="h-5 w-5 mr-1"/>FEATURED</span>)}
                {!!blog.isNew && (<span className="flex items-center bg-green-200 dark:bg-green-900 text-green-800 dark:text-green-100 text-md font-medium px-4 py-0.5 rounded-md mr-2"><SparklesIcon className="h-5 w-5 mr-1"/>NEW</span>)}
                {!!blog.isHot && (<span className="flex items-center bg-red-200 dark:bg-red-900 text-red-800 dark:text-red-100 text-md font-medium px-4 py-0.5 rounded-md mr-2"><FireIcon className="h-5 w-5 mr-1"/>HOT</span>)}
              </div>
              <div class="flex flex-wrap items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400">
                <div class="flex items-center mb-2 md:mb-0"> 
                  <FaCalendarMinus className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>
                  <span>{blog.publishDate} •</span>
                </div>
                <div class="flex items-center mb-2 md:mb-0"> 
                  <FaClock className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>
                  <span>{blog.readTime} read •</span>
                </div>
                <div class="flex items-center mb-2 md:mb-0"> 
                  <BiSolidLike className="h-5 w-5 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>
                  <span>{blog.likeCount} •</span>
                </div>
                <div class="flex items-center mb-2 md:mb-0"> 
                  <FaCommentAlt className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>
                  <span>{blog.commentCount}</span>
                </div>
              </div>
              <div className="flex flex-wrap items-center mb-2 pt-2">
                {blog.category == 'News' && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md lg:text-lg font-medium me-2 px-10 py-1 rounded-lg"><HiNewspaper className="h-5 w-5 mr-1"/>News</span>)}
                {blog.category == 'Tutorials' && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md lg:text-lg font-medium me-2 px-10 py-1 rounded-lg"><PiVideoFill  className="h-5 w-5 mr-1"/>Tutorials</span>)}
                {blog.category == 'Docs' && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md lg:text-lg font-medium me-2 px-10 py-1 rounded-lg"><HiMiniDocumentDuplicate  className="h-5 w-5 mr-1"/>Docs</span>)}
                {blog.category == 'Stories' && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md lg:text-lg font-medium me-2 px-10 py-1 rounded-lg"><PiBookOpenFill  className="h-5 w-5 mr-1"/>Stories</span>)}
              </div>
              <p class="flex items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400"></p>
              <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">{blog.description}</p>
              
              <br />
              <Divider/>
              <br />

              <div className="prose max-w-full">
                {blog.contents ? (
                  blog.contents.map((section, index) => (
                    <React.Fragment key={index}>
                      {section.type === 'paragraph' && (
                        <div className='px-2 lg:px-10'>
                          <ContentRenderer type={section.type} content={section}/>
                        </div>
                      )}
                      {section.type === 'quote' && (
                        <div className='px-2 lg:px-10'>
                          <ContentRenderer type={section.type} content={section}/>
                        </div>
                      )}
                      {section.type === 'list' && (
                        <div className='px-2 lg:px-10'>
                          <ContentRenderer type={section.type} content={section} listTitle={section.listTitle}/>
                        </div>
                      )}
                      {section.type === 'image' && (
                        <div className='px-2 lg:px-10'>
                          <ContentRenderer type={section.type} content={section} imageAlt={section.imageAlt} imageDescription={section.imageDescription} imageUrl={section.imageUrl} imageCredits={section.imageCredits} />
                        </div>
                      )}
                      {section.type === 'table' && (
                        <div className='px-2 lg:px-10'>
                          <ContentRenderer type={section.type} content={section} tableTitle={section.tableTitle} tableDescription={section.tableDescription}/>
                        </div>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <NotFound />
                )}
              </div>

              <br />
              <Divider/>
              <br />

              <p class="flex items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400"><FaCalendarMinus className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/> Publish Date: {blog.publishDate}</p>
              <p class="flex items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400"><FaPen className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/> Author: {blog.author}</p>
              <p class="flex items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400"><FaClock className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/> Read Time: {blog.readTime}</p>
              <p class="flex items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400"><FaHashtag className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/> Revisions: {blog.revisions}</p>
              <p class="flex items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400"><BiSolidCategoryAlt  className="h-5 w-5 ml-1 mr-1 text-gray-600 dark:text-gray-300"/> Category: {blog.category}</p>
              <p class="flex items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400"><BiSolidLike className="h-5 w-5 ml-1 mr-1 text-gray-600 dark:text-gray-300"/> Likes: {blog.likeCount}</p>
              <p class="flex items-center text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400"><FaCommentAlt className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/> Comments: {blog.commentCount}</p>

    
              <br />
              <DividerDot/>
              <br />
              
              <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Read More <span className="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">Interesting </span> Blogs.</h1>
              <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Dive into our treasure trove of captivating blog posts! Discover fascinating insights, thought-provoking stories, and inspiring content.</p>
              <br />
    
              {blogOthers ? (
              <div class="flex flex-wrap">
                {blogOthers.map((blog, index) => (
                  <BlogCard
                    key={index}
                    id={blog.id}
                    imageUrl={blog.imageUrl}
                    title={blog.title}
                    category={blog.category}
                    readTime={blog.readTime}
                    publishDate={blog.publishDate}
                    likeCount={blog.likeCount}
                    commentCount={blog.commentCount}
                    isNew={blog.isNew}
                    isHot={blog.isHot}
                    tag1={blog.tag1}
                    tag2={blog.tag2}
                    tag3={blog.tag3}
                    tag4={blog.tag4}
                    tag5={blog.tag5}
                    tag6={blog.tag6}
                    tag7={blog.tag7}
                    tag8={blog.tag8}
                    link={blog.link}
                    description={blog.description}
                  />
                ))}
              </div>
              ) : (
                <LoadingSpinner />
              )}
            </div>
          ) : (
            <LoadingSpinner />
          )}
          
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Main from './components/Main';
import Portfolio from './components/Portfolio';
import Gallery from './components/Gallery';
import GalleryDetails from './components/GalleryDetails';
import ImageDetails from './components/ImageDetails';
import Blog from './components/Blog';
import BlogDetails from './components/BlogDetails';
import ChangeLog from './components/ChangeLog';
import ContentChangeLog from './components/ContentChangeLog';
import NotFound from './components/NotFound';
import ComingSoon from './components/ComingSoon';
import Contact from './components/Contact';
import Identity from './components/Identity';
import IdentityDetails from './components/IdentityDetails';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/changelog" element={<ChangeLog />} />
        <Route path="/changelog-contents" element={<ContentChangeLog />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId" element={<BlogDetails />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/gallery" element={<Gallery />} />
        <Route path="/portfolio/gallery/:galleryId" element={<GalleryDetails />} />
        <Route path="/portfolio/gallery/:galleryId/image/:imageId" element={<ImageDetails />} />
        <Route path="/portfolio/visualidentities" element={<Identity />} />
        <Route path="/portfolio/visualidentities/:identityId" element={<IdentityDetails />} />
        <Route path="/portfolio/websites" element={<ComingSoon />} />
        <Route path="/help" element={<ComingSoon />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
import { LuServerCrash } from "react-icons/lu";

export default function ErrorServer() {
    return (
        <div class="text-center bg-gray-100 dark:bg-gray-900 py-24 rounded-xl mb-2">
            <div role="status">
                <LuServerCrash class="inline w-16 h-16 py-2 text-sky-500"/>
                <p class="text-md font-normal lg:text-lg text-gray-500 dark:text-gray-400">Server Error...</p>
            </div>
        </div>
    )
}
// IMPORTS : EXTERNAL
import { FaCode } from "react-icons/fa6";
import { IoBug } from "react-icons/io5";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { IoColorPaletteSharp } from "react-icons/io5";

export default function ChangeLogTypeFilter({ filterType, handleFilter }) {
    return (
        <div>
            <button className={`inline-flex mr-2 mb-2 items-center px-5 py-2 text-sm font-medium text-center  ${filterType === '' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('')}>ALL</button>
            <button className={`inline-flex mr-2 mb-2 items-center px-5 py-2 text-sm font-medium text-center ${filterType === 'newPage' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('newPage')}><FaCode className='w-3.5 h-3.5 mr-2'/> New Page</button>
            <button className={`inline-flex mr-2 mb-2 items-center px-5 py-2 text-sm font-medium text-center ${filterType === 'bugFix' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('bugFix')}><IoBug className='w-3.5 h-3.5 mr-2'/> Bug Fix</button>
            <button className={`inline-flex mr-2 mb-2 items-center px-5 py-2 text-sm font-medium text-center ${filterType === 'pageImprovement' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('pageImprovement')}><FaWandMagicSparkles className='w-3.5 h-3.5 mr-2'/> Page Improvement</button>
            <button className={`inline-flex mb-2 items-center px-5 py-2 text-sm font-medium text-center ${filterType === 'uiFix' ? 'bg-sky-600 dark:bg-sky-700 hover:bg-sky-600 dark:hover:bg-sky-700 text-gray-100' : 'text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800'} overflow-hidden transition-all duration-300 rounded-md`} onClick={() => handleFilter('uiFix')}><IoColorPaletteSharp className='w-3.5 h-3.5 mr-2'/> UI and Visuals</button>
        </div>
    )
}
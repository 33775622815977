// IMPORTS : INTERNAL
import NavBar from '../blocks/NavBar';
import BreadCrumb from '../blocks/BreadCrumb';
import DividerDot from '../blocks/DividerDot';
import Footer from '../blocks/Footer';
import ChangeLogList from '../blocks/ChangeLogList';

// IMPORTS : EXTERNAL
import { Helmet } from 'react-helmet';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';

const pages = [
  { name: 'Application ChangeLog', href: '/changelog', current: true },
]

export default function ChangeLog() {

  return (
    <div className="ChangeLog">
      <Helmet><title>Winksen // Application ChangeLog</title></Helmet>
      <NavBar/>

      <body className="bg-gray-50 dark:bg-gray-950 pb-10">
        <div class="container mx-auto px-10 pt-5">

          {/* BreadCrumb */}
          <BreadCrumb pages={pages}/>

          <DividerDot/>
          <br />

          <Link to={`/changelog-contents`} class="mb-4 inline-flex items-center px-10 py-2 text-sm font-medium text-center text-gray-100 dark:text-gray-900 bg-gray-800 dark:bg-gray-200 hover:bg-gray-700 dark:hover:bg-gray-300 overflow-hidden transition-all duration-300 rounded-md">
            Switch to Content ChangeLog
            <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2"/>
          </Link>

          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl">Application ChangeLog</h1>
          <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400">Discover our ChangeLog for all the latest updates, including new Features/Pages, Bug Fixes as well as Page Enhancements.</p>
          <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400">This page helps our users to stay informed about the evolution of our application for an optimized user experience.</p>
          <br />

          <ChangeLogList/>
      
          <Footer/>
        </div>
      </body>
    </div>
  )
}
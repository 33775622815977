// IMPORTS : INTERNAL
import NavBar from '../blocks/NavBar';
import BreadCrumb from '../blocks/BreadCrumb';
import DividerDot from '../blocks/DividerDot';
import Footer from '../blocks/Footer';
import PortfolioList from '../blocks/PortfolioList';

// IMPORTS : EXTERNAL
import { Helmet } from 'react-helmet';

const pages = [
  { name: 'Portfolio', href: '/portfolio', current: true },
]

export default function Portfolio() {
  return (
      <div className="ImageDetails">

        <Helmet><title>Winksen // Image Details</title></Helmet>
        <NavBar/>

        <body className="bg-gray-50 dark:bg-gray-950 pb-10">
          <div class="container mx-auto px-10 pt-5">
            {/* BreadCrumb */}
            <BreadCrumb pages={pages}/>
            <DividerDot/>
            <br />

            <PortfolioList />

            <Footer/>
          </div>
        </body>
      </div>
  )
}
  
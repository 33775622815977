import NavBar from '../blocks/NavBar';
import { Helmet } from 'react-helmet';
import '../App.css';
import GalleryCard from '../blocks/GalleryCard';
import BreadCrumb from '../blocks/BreadCrumb';
import DividerDown from '../blocks/DividerDown';
import DividerDot from '../blocks/DividerDot';
import Modal from '../blocks/Modal';
import Footer from '../blocks/Footer';
import LoadingSpinner from '../blocks/LoadingSpinner'
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SparklesIcon, FireIcon } from '@heroicons/react/20/solid'
import { MdLandscape, MdHistoryEdu, MdOutlineUnfoldMore, MdOutlineUnfoldLess } from "react-icons/md";
import { TiGlobe, TiGroup  } from "react-icons/ti";
import { FaImagePortrait } from "react-icons/fa6";
import { PiBowlFoodFill } from "react-icons/pi";
import { BiSolidCat } from "react-icons/bi";
import { GiAbstract086 } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendarMinus } from "react-icons/fa6";
import { TbMacro } from "react-icons/tb";
import { BsSymmetryVertical } from "react-icons/bs";
import { FaTreeCity } from "react-icons/fa6";
import { VscColorMode } from "react-icons/vsc";
import { FaDraftingCompass } from "react-icons/fa";
import { MdNightlight } from "react-icons/md";
import { IoIosBowtie } from "react-icons/io";
import { FaCity } from "react-icons/fa6";
import { PiSunHorizonFill } from "react-icons/pi";
import { MdAgriculture } from "react-icons/md";
import { FaBridgeWater } from "react-icons/fa6";
import { TbTimelineEventFilled } from "react-icons/tb";
import { MdStars } from "react-icons/md";
import { HiMiniMap } from "react-icons/hi2";
import { BsFillFileImageFill } from "react-icons/bs";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaHandsHoldingCircle } from "react-icons/fa6";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import ImageRenderer from '../blocks/ImageRenderer';
import { PiBirdFill } from "react-icons/pi";
import { FaHouseFloodWater } from "react-icons/fa6";

// Add your own logic to determine if a galleryName or imageId is valid
// const isValidGalleryName = (galleryName) => {
//     // Implement your own validation logic
//     return galleryName === 'malaysia-2023' || galleryName === 'france-2023';
// };

function GalleryDetails() {
  const [gallery, setGallery] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [visibleImages, setVisibleImages] = useState(6);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);
  const [galleryOthers, setGalleryOthers] = React.useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { galleryId } = useParams();
  
  let pages = gallery
  ? [
      { name: 'Portfolio', href: '/portfolio', current: false },
      { name: 'Gallery', href: '/portfolio/gallery', current: false },
      { name: `${gallery.title}`, href: `/portfolio/gallery/${galleryId}`, current: true },
    ]
  : [
      { name: 'Portfolio', href: '/portfolio', current: false },
      { name: 'Gallery', href: '/portfolio/gallery', current: false },
    ];

  useEffect(() => {
    console.log("Entered API Call !!");
    const fetchGallery = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/galleries/${galleryId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch gallery');
        }

        const data = await response.json();
        setGallery(data);
        setImages(data.images);
      } catch (error) {
        console.error('Error fetching gallery:', error.message);
      }
    };

    fetchGallery();
  }, [galleryId]);

  useEffect(() => {
    console.log("Entered API Call !!");
    const fetchBlogOthers = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/galleries/${galleryId}/others`);
        if (!response.ok) {
          throw new Error('Failed to fetch other galleries');
        }

        const data = await response.json();
        setGalleryOthers(data);
      } catch (error) {
        console.error('Error fetching gallery:', error.message);
      }
    };

    fetchBlogOthers();
  }, [galleryId]);

  const openModal = (imageDetails) => {
    console.log(imageDetails);
    setModalData(imageDetails);
    setIsModalOpen(true);
  };

  const closeModal = () => {  
    setIsModalOpen(false);
    // setModalData(null);
  };

  const showMoreImages = () => {
    setVisibleImages((prevVisibleImages) => prevVisibleImages + 5);
  };
  const showLessImages = () => {
    setVisibleImages((prevVisibleImages) => Math.max(prevVisibleImages - 5, 6));
  };

  const renderGrid = (start, end, cols) => {
    return (
      <div className={`grid grid-cols-${cols} gap-2`}>
        {images.slice(start, end).map((image, index) => (
          <div key={index} className="relative overflow-hidden bg-cover bg-no-repeat rounded-md" onClick={() => openModal(images[start + index])}>
            <ImageRenderer image={image} key={index}/>
          </div>
        ))}
      </div>
    );
  };

  const renderImages = () => {
    let renderedImages = [];

    for (let startIndex = 1; startIndex < visibleImages; startIndex += 5) {
      renderedImages.push(renderGrid(startIndex, startIndex+3, 3));
      renderedImages.push(renderGrid(startIndex+3, startIndex+5, 2));
    }

    return renderedImages;
  };
  
  // const navigate = useNavigate();
  // Check if the galleryName is valid (you can implement your own logic here)
  // useEffect(() => {
  //   const isValidGallery = isValidGalleryName(galleryName);

  //   if (!isValidGallery) {
  //     // Use navigate to redirect to 404 if the galleryName is not valid
  //     navigate('/404');
  //   }
  // }, [galleryName, navigate]);

  // if (!isValidGalleryName(galleryName)) {
  //   // Returning null to avoid rendering the component
  //   return null;
  // }

  return (
    <div className="GalleryDetails">
      <Helmet><title>Winksen // Gallery Details</title></Helmet>
      <NavBar/>
      <div className="bg-gray-50 dark:bg-gray-950 pb-10">
        <div className="container mx-auto px-10 pt-5">

          {/* BreadCrumb */}
          <BreadCrumb pages={pages}/>

          <DividerDot/>
          <br />
          
          {gallery ? (
            <div>
              <div className="flex items-center">
                <h1 class="mb-2 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl ">Photo Gallery: <span class="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">{gallery.title}</span></h1>
              </div>
              <div className="mb-2 flex items-center flex-row">
                {!!gallery.isFeatured && (<span className="flex items-center bg-gradient-to-r from-blue-600 to-blue-500 text-blue-100 text-md font-medium px-8 py-0.5 rounded-md mr-2"><MdStars className="h-5 w-5 mr-1"/>FEATURED</span>)}
                {!!gallery.isNew && (<span className="flex items-center bg-gradient-to-r from-emerald-600 to-emerald-500 text-emerald-100 text-md font-medium px-4 py-0.5 rounded-full mr-2"><SparklesIcon className="h-5 w-5 mr-1"/>NEW</span>)}
                {!!gallery.isHot && (<span className="flex items-center bg-gradient-to-r from-red-600 to-red-500 text-red-100 text-md font-medium px-4 py-0.5 rounded-full mr-2"><FireIcon className="h-5 w-5 mr-1"/>HOT</span>)}
              </div>
              {/* <p className='text-gray-500 dark:text-gray-400 text-lg lg:text-xl font-medium mb-2'>Gallery Details:</p> */}
              <div className="text-md lg:text-lg font-medium text-gray-500 dark:text-gray-400 rounded-lg">
                <div className="flex items-center"> 
                  <FaCalendarMinus className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>
                  <span>{gallery.formatted_date}</span>
                </div>
                <div className="flex items-center"> 
                  <FaLocationDot className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>
                  <span>{gallery.location}</span>
                </div>
                <div className="flex items-center"> 
                  <HiMiniMap className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>
                  <span>{gallery.coordinates}</span>
                </div>
                <div className="flex items-center"> 
                  <BsFillFileImageFill className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>
                  <span>{gallery.images_count} Photos</span>
                </div>
              </div>
              <div className="flex flex-wrap items-center mb-2">
                {!!gallery.tag1 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><TiGlobe className="h-5 w-5 mr-1"/>Travel</span>)}
                {!!gallery.tag2 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><MdLandscape className="h-5 w-5 mr-1"/>Landscape</span>)}
                {!!gallery.tag3 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><FaImagePortrait className="h-4 w-4 mr-1"/>Portrait</span>)}
                {!!gallery.tag4 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><TiGroup className="h-5 w-5 mr-1"/>Culture</span>)}
                {!!gallery.tag5 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><PiBowlFoodFill className="h-5 w-5 mr-1"/>Food</span>)}
                {!!gallery.tag6 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><BiSolidCat className="h-5 w-5 mr-1"/>Wildlife</span>)}
                {!!gallery.tag7 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><GiAbstract086 className="h-5 w-5 mr-1"/>Abstract</span>)}
                {!!gallery.tag8 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><MdHistoryEdu className="h-5 w-5 mr-1"/>Historical</span>)}
                {!!gallery.tag9 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><TbMacro className="h-5 w-5 mr-1"/>Macro</span>)}
                {!!gallery.tag10 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><BsSymmetryVertical className="h-5 w-5 mr-1"/>Symmetry</span>)}
                {!!gallery.tag11 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><FaTreeCity className="h-5 w-5 mr-1"/>Street</span>)}
                {!!gallery.tag12 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><VscColorMode className="h-5 w-5 mr-1"/>Black and White</span>)}
                {!!gallery.tag13 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><FaDraftingCompass className="h-4 w-4 mr-1"/>Architecture</span>)}
                {!!gallery.tag14 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><MdNightlight className="h-5 w-5 mr-1"/>Night</span>)}
                {!!gallery.tag15 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><IoIosBowtie className="h-5 w-5 mr-1"/>Fashion</span>)}
                {!!gallery.tag16 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><FaCity className="h-5 w-5 mr-1"/>Cityscapes</span>)}
                {!!gallery.tag17 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><PiSunHorizonFill className="h-5 w-5 mr-1"/>Sunset/Sunrise</span>)}
                {!!gallery.tag18 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><MdAgriculture className="h-5 w-5 mr-1"/>Rural Life</span>)}
                {!!gallery.tag19 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><FaBridgeWater className="h-5 w-5 mr-1"/>Underwater</span>)}
                {!!gallery.tag20 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><TbTimelineEventFilled className="h-5 w-5 mr-1"/>Events</span>)}
                {!!gallery.tag21 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><FaHandsHoldingCircle className="h-5 w-5 mr-1"/>Religious</span>)}
                {!!gallery.tag22 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><PiBirdFill className="h-5 w-5 mr-1"/>Birds</span>)}
                {!!gallery.tag23 && (<span className="flex items-center bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-300 text-md font-medium me-2 px-6 py-0.5 mt-2 rounded-lg"><FaHouseFloodWater className="h-5 w-5 mr-1"/>Sea</span>)}
              </div>
              <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">{gallery.description}</p>
              <br />

              <div className="grid gap-2">
                {/* Display the first image alone */}
                <div className="relative overflow-hidden bg-cover bg-no-repeat rounded-md lg:rounded-lg" onClick={() => openModal(images[0])}>
                  <img className="h-40 sm:h-64 md:h-96 lg:h-120 xl:h-130 w-full object-cover rounded-md lg:rounded-lg overflow-hidden transition duration-300 ease-in-out hover:scale-110" src={images[0].imageUrl}></img>
                  {/* <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src={images[0].imageUrl} alt="" /> */}
                </div>

                {/* Display additional images based on visibility */}
                {renderImages()}

                {/* Display "Show More" and "Show Less" buttons */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {images.length > visibleImages && (
                    <button onClick={showMoreImages} className="inline-flex items-center px-4 py-2 rounded-md mt-4 text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 overflow-hidden transition-all duration-500">
                      Show More <MdOutlineUnfoldMore className="h-5 w-5"/>
                    </button>
                  )}
                  {visibleImages > 6 && (
                    <button onClick={showLessImages} className="inline-flex items-center px-4 py-2 rounded-md mt-4 text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 overflow-hidden transition-all duration-500">
                      Show Less <MdOutlineUnfoldLess className="h-5 w-5"/>
                    </button>
                  )}
                </div>
              </div>
              <br />

              <br />
    
              <Modal isOpen={isModalOpen} onClose={closeModal} imageDetails={modalData} />
    
              <DividerDot/>
              <br />
              
              <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Explore More <span className="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">Stunning </span> Galleries.</h1>
              <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">View more beautiful Galleries.</p>
              <br />

              {galleryOthers ? (
              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:gap-4">
                {galleryOthers.map((gallery, index) => (
                  <GalleryCard
                    key={index}
                    imageUrl={gallery.imageUrl}
                    title={gallery.title}
                    location={gallery.location}
                    formatted_date={gallery.formatted_date}
                    images_count={gallery.images_count}
                    isNew={gallery.isNew}
                    isHot={gallery.isHot}
                    tag1={gallery.tag1}
                    tag2={gallery.tag2}
                    tag3={gallery.tag3}
                    tag4={gallery.tag4}
                    tag5={gallery.tag5}
                    tag6={gallery.tag6}
                    tag7={gallery.tag7}
                    tag8={gallery.tag8}
                    tag9={gallery.tag9}
                    tag10={gallery.tag10}
                    tag11={gallery.tag11}
                    tag12={gallery.tag12}
                    tag13={gallery.tag13}
                    tag14={gallery.tag14}
                    tag15={gallery.tag15}
                    tag16={gallery.tag16}
                    tag17={gallery.tag17}
                    tag18={gallery.tag18}
                    tag19={gallery.tag19}
                    tag20={gallery.tag20}
                    id={gallery.id}
                    description={gallery.description}
                  />
                ))}
              </div>
              ) : (
                <LoadingSpinner />
              )}
            </div>
          ) : (
            <LoadingSpinner />
          )}
          
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default GalleryDetails;

import { React } from 'react';

export default function ContentRenderer({ type, content, listTitle, tableTitle, tableDescription, imageAlt, imageDescription, imageUrl, imageCredits }) {
  const htmlContent = content.content;
  const htmlContentNoQuotes = htmlContent.replace(/^"|"$/g, '');

  let contentArray = [];
  let contentMatrix = [];

  if (type === 'list') {
    contentArray = JSON.parse(htmlContent);
    const jsonString = contentArray.replace(/'/g, '"');
    contentArray = JSON.parse(jsonString);
  }
  if (type === 'table') {
    const trimmedString = htmlContent.slice(1, -1);
    contentMatrix = trimmedString.split("],[").map(row => row.replace(/[\[\]]/g, '').split(','));
  }
  
  return (
    <div>
      {type === 'paragraph' && (
        <div className="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400" dangerouslySetInnerHTML={{ __html: htmlContentNoQuotes }} />
      )}
      {type === 'quote' && (
        <blockquote className="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400 border-l-4 border-gray-400 pl-4 italic">{htmlContentNoQuotes}</blockquote>
      )}
      {type === 'list' && (
        <div>
          <p className="text-xl lg:text-2xl font-semibold text-gray-900 dark:text-gray-100">{listTitle}</p>
          <ul className="list-disc list-inside">
            {contentArray.map((item, idx) => (
              <li className="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400" key={idx}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      {type === 'table' && (
        <div class="relative overflow-x-auto rounded-lg border-2 border-gray-300 dark:border-gray-700">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg ">
            <caption class="pl-5 pt-2 text-xl lg:text-2xl font-semibold text-gray-900 dark:text-gray-100 text-left rtl:text-right bg-gray-50 dark:bg-gray-950">
              {tableTitle}
              <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400 mb-2">{tableDescription}</p>
            </caption>
            <thead class="bg-gray-100 dark:bg-gray-900 border-b border-gray-100 dark:border-gray-900 rounded-lg">
              <tr>
                {contentMatrix[0].map((heading, idx) => (
                  <th scope="col" class="px-6 py-3" key={idx}>{heading.replace(/'/g, '')}</th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {contentMatrix.slice(1).map((row, rowIndex) => (
                <tr className='bg-gray-50 border-b dark:bg-gray-950 border-gray-100 dark:border-gray-900' key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td scope="col" class="px-6 py-3" key={cellIndex}>{cell.replace(/'/g, '')}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {type === 'image' && (
        <div>
          <div className="relative overflow-hidden rounded-xl">
            <img className="h-40 sm:h-56 md:h-72 lg:h-96 w-full object-cover rounded-xl overflow-hidden" src={imageUrl} alt={imageAlt} />
          </div>
          <p className="text-center text-md font-normal lg:text-lg text-gray-500 dark:text-gray-400"><b>Description:</b> {imageDescription}</p>
          <p className="text-center text-md font-normal lg:text-lg text-gray-500 dark:text-gray-400"><b>Alt:</b> {imageAlt}</p>
          <p className="text-center text-md font-normal lg:text-lg text-gray-500 dark:text-gray-400"><b>Credits:</b> {imageCredits}</p>
        </div>
      )}
    </div>
  );
}

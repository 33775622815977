import { LuServerCrash } from "react-icons/lu";

export default function ErrorServerFeatured() {
    return (
        <div class="text-center bg-sky-700 py-16 rounded-xl mb-2">
            <div role="status">
                <LuServerCrash class="inline w-16 h-16 py-2 text-gray-100"/>
                <p class="text-md font-normal lg:text-lg text-gray-300">Server Error...</p>
            </div>
        </div>
    )
}
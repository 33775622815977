// IMPORTS : INTERNAL
import NavBar from '../blocks/NavBar';
import BreadCrumb from '../blocks/BreadCrumb';
import Footer from '../blocks/Footer';
import DividerDot from '../blocks/DividerDot';
import GalleryList from '../blocks/GalleryList';

// IMPORTS : EXTERNAL
import { Helmet } from 'react-helmet';
import GalleryFeatured from '../blocks/GalleryFeatured';

const pages = [
  { name: 'Portfolio', href: '/portfolio', current: false },
  { name: 'Gallery', href: '/portfolio/gallery', current: true },
]

function Gallery() {
  return (
    <div className="Gallery">
      <Helmet><title>Winksen // Gallery</title></Helmet>
      <NavBar/>
      <div className="bg-gray-50 dark:bg-gray-950 pb-10">
        <div class="container mx-auto px-10 pt-5">

          {/* BreadCrumb */}
          <BreadCrumb pages={pages}/>

          <DividerDot/>
          <br />
          
          <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl">Discover Our Featured <span class="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">Photo Gallery</span>!</h1>
          <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400">Dive into a world where every frame tells a story. Explore Our Image Gallery, a curated collection of captivating visuals that freeze moments in time. From the play of light to the raw emotions, each image is a testament to the art of photography and the beauty found in the ordinary.</p>
          <br />

          <div className="container">
            <GalleryFeatured/>
            <GalleryList />
          </div>

          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default Gallery;

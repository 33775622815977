import { MdError } from "react-icons/md";

export default function ErrorGeneralFeatured() {
    return (
        <div class="text-center bg-sky-700 py-16 rounded-xl mb-2">
            <div role="status">
                <MdError class="inline w-16 h-16 py-2 text-gray-100"/>
                <p class="text-md font-normal lg:text-lg text-gray-300">There Was an Error fetching Data from the Server, please try again later...</p>
            </div>
        </div>
    )
}
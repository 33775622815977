// IMPORTS : INTERNAL
import ErrorServer from './ErrorServer';
import ErrorGeneral from './ErrorGeneral';
import LoadingSpinner from './LoadingSpinner';
import NotFound from './NotFound';
import ChangeLogTypeFilter from './ChangeLogTypeFilter';

// IMPORTS : EXTERNAL
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCode } from "react-icons/fa6";
import { IoBug } from "react-icons/io5";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { IoColorPaletteSharp } from "react-icons/io5";
import { PiLinkSimpleBold } from "react-icons/pi";
import { SparklesIcon, FireIcon } from '@heroicons/react/20/solid'
import { parseISO, isBefore, differenceInHours } from 'date-fns';

function ChangeLogElement({ type, href, name, comment, date, timestamp, version }) {
  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    const twelveHoursAgo = new Date();
    twelveHoursAgo.setHours(twelveHoursAgo.getHours() - 12);

    // Parse timestamp from backend
    const parsedTimestamp = parseISO(timestamp);

    // Check if the timestamp is less than 12 hours ago
    const isNew = isBefore(parsedTimestamp, twelveHoursAgo);

    setShowIndicator(!isNew);
  }, [timestamp]);

  return (
    <div className='relative flex items-start space-x-3'>
      {type === 'newPage' ? (
        <>
          <div className="relative">
            <FaCode className='h-10 w-10 p-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-full  flex items-center justify-center ring-8 ring-gray-200 dark:ring-gray-800'/>
          </div>
          <div className="min-w-0 flex-1">
            <div>
              <div className="text-sm">
                {showIndicator && (
                  <p className="text-gray-900 dark:text-white ml-1 mb-2">
                    <span className='bg-green-200 dark:bg-green-900 text-green-800 dark:text-green-100 py-0.5 px-3 rounded-md inline-flex items-center'>
                      <SparklesIcon className="h-3 w-3 mr-1"/> New
                    </span>
                  </p>
                )}
                <p className='font-medium text-gray-900 dark:text-white ml-1 mb-2'>New Page Added: </p>
                {href ? (
                  <p className="font-medium text-gray-900 dark:text-white ml-1 mb-2">
                    <span className='bg-gray-200 dark:bg-gray-800 py-1 px-3 rounded-md'><Link className='inline-flex items-center' to={href}>{name} <PiLinkSimpleBold className='ml-2 h-4 w-4'/></Link></span>
                  </p>
                ) : (
                  <p className="font-medium text-gray-900 dark:text-white ml-1 mb-2">
                    <span className='bg-gray-200 dark:bg-gray-800 py-1 px-3 rounded-md'>{name}</span>
                  </p>
                )}
              </div>
              <p className="mt-0.5 text-md text-gray-700 dark:text-gray-400">{comment}</p>
              <p className="mt-0.5 text-sm text-gray-500">Added {date}</p>
              <p className="mt-0.5 text-sm text-gray-500">Version v{version}</p>
            </div>
          </div>
        </>
      ) : type === 'bugFix' ? (
        <>
          <div className="relative">
            <IoBug className='h-10 w-10 p-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-full  flex items-center justify-center ring-8 ring-gray-200 dark:ring-gray-800'/>
          </div>
          <div className="min-w-0 flex-1">
            <div>
              <div className="text-sm">
                {showIndicator && (
                  <p className="text-gray-900 dark:text-white ml-1 mb-2">
                    <span className='bg-green-200 dark:bg-green-900 text-green-800 dark:text-green-100 py-0.5 px-3 rounded-md inline-flex items-center'>
                      <SparklesIcon className="h-3 w-3 mr-1"/> New
                    </span>
                  </p>
                )}
                <p className='font-medium text-gray-900 dark:text-white ml-1 mb-2'>Bug Squashed: </p>
                {href ? (
                  <p className="font-medium text-gray-900 dark:text-white ml-1 mb-2">
                    <span className='bg-gray-200 dark:bg-gray-800 py-1 px-3 rounded-md'><Link className='inline-flex items-center' to={href}>{name} <PiLinkSimpleBold className='ml-2 h-4 w-4'/></Link></span>
                  </p>
                ) : (
                  <p className="font-medium text-gray-900 dark:text-white ml-1 mb-2">
                    <span className='bg-gray-200 dark:bg-gray-800 py-1 px-3 rounded-md'>{name}</span>
                  </p>
                )}
              </div>
              <p className="mt-0.5 text-md text-gray-700 dark:text-gray-400">{comment}</p>
              <p className="mt-0.5 text-sm text-gray-500">Added {date}</p>
              <p className="mt-0.5 text-sm text-gray-500">Version v{version}</p>
            </div>
          </div>
        </>
      ) : type === 'pageImprovement' ? (
        <>
          <div className="relative">
            <FaWandMagicSparkles className='h-10 w-10 p-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-full  flex items-center justify-center ring-8 ring-gray-200 dark:ring-gray-800'/>
          </div>
          <div className="min-w-0 flex-1">
            <div className="text-sm">
              {showIndicator && (
                <p className="text-gray-900 dark:text-white ml-1 mb-2">
                  <span className='bg-green-200 dark:bg-green-900 text-green-800 dark:text-green-100 py-0.5 px-3 rounded-md inline-flex items-center'>
                    <SparklesIcon className="h-3 w-3 mr-1"/> New
                  </span>
                </p>
              )}
              <p className='font-medium text-gray-900 dark:text-white ml-1 mb-2'>Page Improved: </p>
              {href ? (
                <p className="font-medium text-gray-900 dark:text-white ml-1 mb-2">
                  <span className='bg-gray-200 dark:bg-gray-800 py-1 px-3 rounded-md'><Link className='inline-flex items-center' to={href}>{name} <PiLinkSimpleBold className='ml-2 h-4 w-4'/></Link></span>
                </p>
              ) : (
                <p className="font-medium text-gray-900 dark:text-white ml-1 mb-2">
                  <span className='bg-gray-200 dark:bg-gray-800 py-1 px-3 rounded-md'>{name}</span>
                </p>
              )}
              
            </div>
            <p className="mt-0.5 text-md text-gray-700 dark:text-gray-400">{comment}</p>
            <p className="mt-0.5 text-sm text-gray-500">Added {date}</p>
            <p className="mt-0.5 text-sm text-gray-500">Version v{version}</p>
          </div>
        </>
      ) : type === 'uiFix' ? (
        <>
          <div className="relative">
            <IoColorPaletteSharp className='h-10 w-10 p-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-full flex items-center justify-center ring-8 ring-gray-200 dark:ring-gray-800'/>
          </div>
          <div className="min-w-0 flex-1">
            <div>
              <div className="text-sm">
                {showIndicator && (
                  <p className="text-gray-900 dark:text-white ml-1 mb-2">
                    <span className='bg-green-200 dark:bg-green-900 text-green-800 dark:text-green-100 py-0.5 px-3 rounded-md inline-flex items-center'>
                      <SparklesIcon className="h-3 w-3 mr-1"/> New
                    </span>
                  </p>
                )}
                <p className='font-medium text-gray-900 dark:text-white ml-1 mb-2'>UI Fixed: </p>
                {href ? (
                  <p className="font-medium text-gray-900 dark:text-white ml-1 mb-2">
                    <span className='bg-gray-200 dark:bg-gray-800 py-1 px-3 rounded-md'><Link className='inline-flex items-center' to={href}>{name} <PiLinkSimpleBold className='ml-2 h-4 w-4'/></Link></span>
                  </p>
                ) : (
                  <p className="font-medium text-gray-900 dark:text-white ml-1 mb-2">
                    <span className='bg-gray-200 dark:bg-gray-800 py-1 px-3 rounded-md'>{name}</span>
                  </p>
                )}
              </div>
              <p className="mt-0.5 text-md text-gray-700 dark:text-gray-400">{comment}</p>
              <p className="mt-0.5 text-sm text-gray-500">Added {date}</p>
              <p className="mt-0.5 text-sm text-gray-500">Version v{version}</p>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ChangeLogElement;
import Divider from './Divider';
import { SparklesIcon, FireIcon } from '@heroicons/react/20/solid'
import { MdLandscape, MdHistoryEdu } from "react-icons/md";
import { TiGlobe, TiGroup  } from "react-icons/ti";
import { FaImagePortrait } from "react-icons/fa6";
import { PiBowlFoodFill } from "react-icons/pi";
import { BiSolidCat } from "react-icons/bi";
import { GiAbstract086 } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendarMinus } from "react-icons/fa6";
import { TbMacro } from "react-icons/tb";
import { BsSymmetryVertical } from "react-icons/bs";
import { FaTreeCity } from "react-icons/fa6";
import { VscColorMode } from "react-icons/vsc";
import { FaDraftingCompass } from "react-icons/fa";
import { MdNightlight } from "react-icons/md";
import { IoIosBowtie } from "react-icons/io";
import { FaCity } from "react-icons/fa6";
import { PiSunHorizonFill } from "react-icons/pi";
import { MdAgriculture } from "react-icons/md";
import { FaBridgeWater } from "react-icons/fa6";
import { TbTimelineEventFilled } from "react-icons/tb";
import { MdStars } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import { BsFillFileImageFill } from "react-icons/bs";
import { FaHandsHoldingCircle } from "react-icons/fa6";
import { PiBirdFill } from "react-icons/pi";
import { FaHouseFloodWater } from "react-icons/fa6";

function CardIdentity({ logo, title, description, isNew, isHot, isFeatured, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, id }) {
  return (
    <div class="max-w-full mb-4 bg-gray-100 rounded-xl dark:bg-gray-900 border-2 border-gray-100 dark:border-gray-900 overflow-hidden transition-all duration-300 hover:border-sky-600 dark:hover:border-sky-500">
      <Link to={`/portfolio/visualidentities/${id}`}>
        <div className="relative">
          <div className='pt-7 px-7'>
            <img className="h-full w-full object-cover rounded-xl shadow-lg" src={logo} alt="" />
          </div>
        </div>
        <div class="p-5">
          <h5 class="mr-2 mb-1 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">{title}</h5>
          <div className="mb-2 flex items-center flex-row">
            {!!isFeatured && (<span className="flex items-center bg-blue-500 text-white text-xs font-medium px-4 py-0.5 rounded-full mr-2"><MdStars className="h-4 w-4 mr-1"/>FEATURED</span>)}
            {!!isNew && (<span className="flex items-center bg-green-200 dark:bg-green-900 text-green-800 dark:text-green-100 text-xs font-medium px-4 py-0.5 rounded-full mr-2"><SparklesIcon className="h-4 w-4 mr-1"/>NEW</span>)}
            {!!isHot && (<span className="flex items-center bg-red-200 dark:bg-red-900 text-red-800 dark:text-red-100 text-xs font-medium px-4 py-0.5 rounded-full mr-2"><FireIcon className="h-4 w-4 mr-1"/>HOT</span>)}
          </div>
          {/* <p class="flex items-center font-normal text-gray-500 dark:text-gray-400"><FaCalendarMinus className="h-4 w-4 ml-1 mr-1 text-gray-600 dark:text-gray-300"/>{formatted_date}</p> */}
          <Divider/>
          <div className="flex flex-wrap items-center mb-2 pt-2">
            {!!tag1 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><TiGlobe className="h-5 w-5 mr-1"/>Travel</span>)}
            {!!tag2 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><MdLandscape className="h-5 w-5 mr-1"/>Landscape</span>)}
            {!!tag3 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><FaImagePortrait className="h-4 w-4 mr-1"/>Portrait</span>)}
            {!!tag4 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><TiGroup className="h-5 w-5 mr-1"/>Culture</span>)}
            {!!tag5 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><PiBowlFoodFill className="h-5 w-5 mr-1"/>Food</span>)}
            {!!tag6 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><BiSolidCat className="h-5 w-5 mr-1"/>Wildlife</span>)}
            {!!tag7 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><GiAbstract086 className="h-5 w-5 mr-1"/>Abstract</span>)}
            {!!tag8 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><MdHistoryEdu className="h-5 w-5 mr-1"/>Historical</span>)}
            {!!tag9 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><TbMacro className="h-5 w-5 mr-1"/>Macro</span>)}
            {!!tag10 && (<span className="flex items-center bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 mt-2 rounded-md"><BsSymmetryVertical className="h-5 w-5 mr-1"/>Symmetry</span>)}
          </div>
          <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">{description}</p>
          <Link to={`/portfolio/visualidentities/${id}`} class="inline-flex items-center px-10 py-2 text-sm font-medium text-center text-gray-900 dark:text-gray-100 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 overflow-hidden transition-all duration-300 rounded-md">
            View Full Identity
            <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2 hidden md:block"/>
          </Link>
        </div>
      </Link>
    </div>
  );
}

export default CardIdentity;
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <div className="relative bg-gray-50 dark:bg-gray-950 overflow-hidden">
      <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
        <div className="relative h-full max-w-7xl mx-auto">
          <svg
            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-100 dark:text-gray-900" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-100 dark:text-gray-900" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
          </svg>
        </div>
      </div>

      <div className="relative pt-6 pb-16 sm:pb-24">
        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl hepta-slab-7">The Creative Space for <span class="bg-gradient-to-r from-sky-600 to-sky-400 text-transparent bg-clip-text">YOU</span>!</h1>
            <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400">Hi, my name is Amine and I'm passionate about Art and Creativity.</p>
            <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400">Check our Porftolio or Contact us Today!</p>
            <div className="mt-5 mx-auto sm:flex sm:justify-center md:mt-8">
              {/* <Link to='portfolio' class="mr-2 mb-2 inline-flex items-center px-10 py-2 text-sm font-medium text-center text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-gray-800 overflow-hidden transition-all duration-300 rounded-md">
                Discover Our Portfolio
                <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2"/>
              </Link> */}
              <Link to='contact' class="mb-2 inline-flex items-center px-10 py-2 text-lg font-medium text-center text-gray-100 dark:text-gray-900 bg-gray-800 dark:bg-gray-200 hover:bg-gray-700 dark:hover:bg-gray-300 overflow-hidden transition-all duration-300 rounded-md">
                Contact Us Today
                <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2"/>
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
import { useState, useEffect } from 'react';
import ErrorServerFeatured from './ErrorServerFeatured';
import ErrorGeneralFeatured from './ErrorGeneralFeatured';
import NotFoundFeatured from './NotFoundFeatured';
import IdentityCardFeatured from './IdentityCardFeatured';
import LoadingSpinnerFeatured from './LoadingSpinnerFeatured';

export default function IdentityFeatured() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);
  const [featuredIdentity, setFeaturedIdentity] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => { fetchFeaturedIdentity(); }, []);

  const fetchFeaturedIdentity = () => {
    fetch(`${baseUrl}/api/identities/featured`)
      .then(response => {
        if (!response.ok) {
          // Handle server errors
          setServerError(true);
          setLoading(false);
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(data => {
        // Simulate a timeout after 10 seconds
        setTimeout(() => {
          if (loading) {
            setTimeoutError(true);
            setLoading(false);
          }
        }, 1000);
        
        // Handle successful response
        if (data) { setFeaturedIdentity(data); }
        setLoading(false);
      })
      .catch(error => {
        // console.error('Error fetching blogs:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  if (loading) {
    return <div>
      <LoadingSpinnerFeatured />
    </div> 
  }

  if (error) {
    if (error.includes('404')) {
      return <NotFoundFeatured />;
    } else if (error.includes('500')) {
      return <ErrorServerFeatured />;
    } else {
      return <ErrorGeneralFeatured />;
    }
  }

  return (
    <div>
      <div className="container">
        <IdentityCardFeatured
          logo={featuredIdentity.logo}
          logoMark={featuredIdentity.logoMark}
          logoSecondary1={featuredIdentity.logoSecondary1}
          logoMarkSecondary1={featuredIdentity.logoMarkSecondary1}
          logoSecondary2={featuredIdentity.logoSecondary2}
          logoMarkSecondary2={featuredIdentity.logoMarkSecondary2}
          logoSecondary3={featuredIdentity.logoSecondary3}
          logoMarkSecondary3={featuredIdentity.logoMarkSecondary3}
          logoSecondary4={featuredIdentity.logoSecondary4}
          logoMarkSecondary4={featuredIdentity.logoMarkSecondary4}
          logoBW={featuredIdentity.logoBW}
          logoMarkBW={featuredIdentity.logoMarkBW}
          typography={featuredIdentity.typography}
          typographyImage={featuredIdentity.typographyImage}
          mockup1={featuredIdentity.mockup1}
          mockup2={featuredIdentity.mockup2}
          mockup3={featuredIdentity.mockup3}
          mockup4={featuredIdentity.mockup4}
          mockup5={featuredIdentity.mockup5}
          mockup6={featuredIdentity.mockup6}
          mockup7={featuredIdentity.mockup7}
          title={featuredIdentity.title}
          formatted_date={featuredIdentity.formatted_date}
          images_count={featuredIdentity.images_count}
          isNew={featuredIdentity.isNew}
          isHot={featuredIdentity.isHot}
          tag1={featuredIdentity.tag1}
          tag2={featuredIdentity.tag2}
          tag3={featuredIdentity.tag3}
          tag4={featuredIdentity.tag4}
          tag5={featuredIdentity.tag5}
          tag6={featuredIdentity.tag6}
          tag7={featuredIdentity.tag7}
          tag8={featuredIdentity.tag8}
          tag9={featuredIdentity.tag9}
          tag10={featuredIdentity.tag10}
          id={featuredIdentity.id}
          description={featuredIdentity.description}
        />
      </div>
    </div>
  );
}

import { MdError } from "react-icons/md";

export default function GeneralError() {
    return (
        <div class="text-center bg-gray-100 dark:bg-gray-900 py-24 rounded-xl mb-2">
            <div role="status">
                <MdError class="inline w-16 h-16 py-2 text-sky-500"/>
                <p class="text-md font-normal lg:text-lg text-gray-500 dark:text-gray-400">There Was an Error fetching Data from the Server, please try again later...</p>
            </div>
        </div>
    )
}
// IMPORTS : INTERNAL
import NavBar from '../blocks/NavBar';
import BreadCrumb from '../blocks/BreadCrumb';
import Footer from '../blocks/Footer';
import DividerDot from '../blocks/DividerDot';
import ContactForm from '../blocks/ContactForm';

// IMPORTS : EXTERNAL
import { Helmet } from 'react-helmet';

const pages = [
  { name: 'Contact', href: '/contact', current: false },
]

export default function Contact() {
  return (
    <div className="Contact">
      <Helmet><title>Winksen // Contact</title></Helmet>
      <NavBar/>
      <div className="bg-gray-50 dark:bg-gray-950 pb-10">
          <div class="container mx-auto px-10 pt-5">

            {/* BreadCrumb */}
            <BreadCrumb pages={pages}/>

            <DividerDot/>
            <br />
            
            <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-gray-100 md:text-5xl lg:text-6xl">Contact Us</h1>
            {/* <p class="text-lg font-normal lg:text-xl text-gray-500 dark:text-gray-400">Contact Us Today!</p> */}
            <br />

            <div className="container">
              <ContactForm />
            </div>

            <Footer/>
          </div>
      </div>
    </div>
  )
}
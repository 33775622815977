import { RiHome5Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

function BreadCrumb({ pages }) {
  return (
    <nav className="flex justify-center pb-4" aria-label="Breadcrumb">
      <ol role="list" className="flex space-x-4 rounded-md bg-gray-100 dark:bg-gray-900 px-6">
        <li className="flex">
          <div className="flex items-center">
            <Link to="/" className="text-gray-900 dark:text-gray-100 hover:text-sky-500 dark:hover:text-sky-500">
              <RiHome5Fill className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-300 dark:text-gray-700"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={page.href}
                className="ml-4 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-sky-500 dark:hover:text-sky-500"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default BreadCrumb;
import { CameraIcon, DevicePhoneMobileIcon } from '@heroicons/react/20/solid'
import { SiAdobelightroom, SiAdobephotoshop } from "react-icons/si";
import { IoIosArrowForward } from "react-icons/io";
import { RiCameraLensFill } from "react-icons/ri";
import { FaInfoCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa6";
import { TbPhotoFilled } from "react-icons/tb";

const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(<FaStar key={i} className="w-5 h-5 text-yellow-500" />);
    } else {
      stars.push(<FaStar key={i} className="w-5 h-5 text-gray-300 dark:text-gray-700" />);
    }
  }
  return stars;
};

export default function ImageDescription({ title, alt, gallery, galleryId, formatted_date, location, coordinates, dimensions, size, imageType, fileName, camera, lens, cameraType, focalLength, shutterSpeed, aperture, iso, software, rating }) {
  return (
    <div className="ImageDescription">
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3">
        <div>
          <div className="overflow-hidden px-8 bg-gray-100 dark:bg-gray-900 rounded-lg mb-3">
            <div className="py-3 text-center">
              <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 inline-flex items-center"><FaInfoCircle class="rtl:rotate-180 w-5 h-5 ms-2 mr-2"/> General Information</h3>
            </div>
          </div>
          <div className="overflow-hidden px-8 bg-gray-100 dark:bg-gray-900 rounded-lg">
            <div className="py-5">
              <dl className="divide-y divide-gray-200 dark:divide-gray-800">
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Description</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{title || '-'}</dd>
                </div>
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-400 py-2">Gallery</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 col-span-2">
                    <Link to={`/portfolio/gallery/${galleryId}`} class="inline-flex items-center px-10 py-2 text-sm font-medium text-center text-gray-900 dark:text-gray-100 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 overflow-hidden transition-all duration-300 rounded-md">
                      {gallery || '-'}
                      <IoIosArrowForward class="rtl:rotate-180 w-3.5 h-3.5 ms-2"/>
                    </Link>
                  </dd>
                </div>
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-400">Image Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 col-span-2 inline-flex items-center">
                    <span class="inline-flex items-center px-4 py-1 text-sm font-medium text-center text-gray-900 dark:text-gray-100 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 overflow-hidden transition-all duration-300 rounded-md">
                      <TbPhotoFilled class="w-3.5 h-3.5 mr-1"/>
                      {imageType || '-'}
                    </span>
                  </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Capture Date</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{formatted_date || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Location</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{location  || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Coordinates</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{coordinates  || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Dimensions</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{dimensions || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Rating</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0 inline-flex items-center">
                    {renderStars(rating)}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>


        <div>
          <div className="overflow-hidden px-8 bg-gray-100 dark:bg-gray-900 rounded-lg mb-3">
            <div className="py-3 text-center">
              <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 inline-flex items-center"><RiCameraLensFill class="rtl:rotate-180 w-5 h-5 ms-2 mr-2"/> Detailed Information</h3>
            </div>
          </div>
          <div className="overflow-hidden px-8 bg-gray-100 dark:bg-gray-900 rounded-lg">
            <div className="py-5">
              <dl className="divide-y divide-gray-200 dark:divide-gray-800">
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Original File Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{fileName || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Original File Size</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{size || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Camera</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{camera || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Lens</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{lens || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Camera Type</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">
                    <div className="flex items-center">
                      <dt className="text-gray-900 dark:text-gray-100">{cameraType || '-'} {cameraType && '•'}&nbsp;</dt>
                      {cameraType === 'Mobile' ? (
                        <DevicePhoneMobileIcon className="h-5 w-5" />
                      ) : cameraType === 'DSLR' ? (
                        <CameraIcon className="h-5 w-5" />
                      ) : (
                        // Add more conditions as needed
                        null // or provide a default icon or null if none matches
                      )}
                    </div>
                  </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Focal Length</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{focalLength || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Shutter Speed</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{shutterSpeed || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Aperture</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{aperture || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">ISO</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{iso || '-'}</dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Software</dt>
                  {/* <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{software} / <SiAdobelightroom /></dd> */}
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">
                    <div className="flex items-center">
                      
                      {software === 'Adobe Lightroom' ? (
                        <SiAdobelightroom className="h-5 w-5" />
                      ) : software === 'Adobe Photoshop' ? (
                        <SiAdobephotoshop className="h-5 w-5" />
                      ) : (
                        // Add more conditions as needed
                        null // or provide a default icon or null if none matches
                      )}
                      {software && '\u00A0\u00A0'}
                      <dt className="text-gray-900 dark:text-gray-100">{software || '-'}</dt>
                    </div>
                  </dd>
                </div>
                <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-400">Alt</dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:col-span-2 sm:mt-0">{alt || '-'}</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
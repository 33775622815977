import { RxDotFilled } from "react-icons/rx";

export default function Divider() {
    return (
        <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-200 dark:border-gray-800" />
            </div>
            <div className="relative flex justify-center">
            <span className="bg-gray-50 dark:bg-gray-950 px-2">
                <RxDotFilled className="h-5 w-5 text-gray-900 dark:text-gray-100" aria-hidden="true" />
            </span>
            </div>
        </div>
    )
}
import { TbError404 } from "react-icons/tb";

export default function NotFound() {
    return (
        <div class="text-center bg-sky-700 py-16 rounded-xl mb-2">
            <div role="status">
                <TbError404 class="inline w-16 h-16 text-gray-100"/>
                <p class="text-md font-normal lg:text-lg text-gray-300">No Featured Contents Found, for Now...</p>
            </div>
        </div>
    )
}